import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const getUrl = window.location;
const baseUrl = getUrl.protocol + "//" + getUrl.host;

const pages = [
   { name: "overview", link: `${baseUrl}/events/west22` },
   { name: "investors", link: `${baseUrl}/events/west22/#custom-stop` },
   { name: "agenda", link: `${baseUrl}/events/west22/agenda` },
   { name: "sponsors", link: `${baseUrl}/events/west22/sponsors` },
   { name: "faq", link: `${baseUrl}/events/west22/FAQ` },
   { name: "matchmaking", link: `${baseUrl}/events/west22/matchmaking` },
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];


export const NavBarTest = () => {
   const [anchorElNav, setAnchorElNav] = React.useState(null);
   const [anchorElUser, setAnchorElUser] = React.useState(null);
   const [chevron, setChevron] = useState(0);
   const [chevron2, setChevron2] = useState(0);
   const [chevron3, setChevron3] = useState(0);
   const [chevron4, setChevron4] = useState(0);
   const [margin1, setMargin1] = useState("0vw");
   const [margin2, setMargin2] = useState("0vw");
   const [margin3, setMargin3] = useState("0vw");
   const [margin4, setMargin4] = useState("0vw");

   const handleOpenNavMenu = (event) => {
      setAnchorElNav(event.currentTarget);
   };

   const handleCloseNavMenu = () => {
      setAnchorElNav(null);
   };

   const handleCloseUserMenu = () => {
      setAnchorElUser(null);
   };

   return (
      <AppBar
         position="sticky"
         sx={{
            backgroundColor: "#212529",
            m: 0,
            width: "100%",
            fontFamily: "Poppins, sans-serif !important",
         }}
      >
         <Container maxWidth="xl" sx={{ margin: "auto" }}>
            <Toolbar disableGutters={true}>
               <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                     mr: 2,
                     flex: 1,
                     fontFamily: "Poppins, sans-serif !important",
                     display: { xs: "none", md: "flex" },
                  }}
               >
                  <div style={{ display: "flex", alignItems: "center" }}>
                     <a href="https://youngstartup.com/upcoming"><img
                        alt='vsd' src="/assets/events/west22/VSDCLogoWhite-01.png"
                        style={{ objectFit: "scale-down", maxWidth: "250px" }}
                     /></a>
                     <div
                        style={{
                           borderLeft: "1px solid white",
                           marginRight: "15px",
                           height: "80%",
                        }}
                     ></div>
                     <div style={{ display: "flex", flexDirection: "column" }}>
                        <h5
                           style={{
                              fontFamily: "Poppins, sans-serif !important",
                              fontSize: "1rem",
                              marginBottom: "0 !important",
                           }}
                        >
                           March 29-31, 2022
                        </h5>
                        <h5 style={{ fontSize: "1rem", marginBottom: "0 !important" }}>
                           Online
                        </h5>
                     </div>
                  </div>
               </Typography>

               <Box
                  sx={{ flex: 1, flexGrow: 1, display: { xs: "flex", md: "none" } }}
               >
                  <IconButton
                     size="large"
                     aria-label="account of current user"
                     aria-controls="menu-appbar"
                     aria-haspopup="true"
                     onClick={handleOpenNavMenu}
                     color="inherit"
                  >
                     <MenuIcon />
                  </IconButton>
                  <Menu
                     id="menu-appbar"
                     anchorEl={anchorElNav}
                     anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                     }}
                     open={Boolean(anchorElNav)}
                     onClose={handleCloseNavMenu}
                     sx={{
                        display: { xs: "block", md: "none" },
                     }}
                  >
                     {pages.map((page) => (
                        <MenuItem
                           key={page.name}
                           onClick={handleCloseNavMenu}
                           sx={{ backgroundColor: "black" }}
                        >
                           <a href={page.link}>
                              <Typography
                                 textAlign="center"
                                 sx={{
                                    fontFamily: "Poppins, sans-serif !important",
                                    color: "white",
                                 }}
                              >
                                 {page.name}
                              </Typography>
                           </a>
                        </MenuItem>
                     ))}
                     <MenuItem sx={{ backgroundColor: 'black' }}>
                        <a href={`${baseUrl}/events/west22/registration`} style={{ width: '100%' }}>
                           <button
                              onMouseOver={(e) => {
                                 setChevron3(1);
                                 setMargin3("1.5vw");
                                 
                              }}
                              onMouseLeave={() => {
                                 setChevron3(0);
                                 setMargin3("0vw");
                              }}
                              className="dark-btn"
                              style={{
                                 marginRight: "1rem",
                                 fontWeight: "900",
                                 fontSize: "18px",
                                 width: '100%'
                              }}
                           >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                 <ChevronRightIcon sx={{ opacity: "0" }} />
                                 <div>Register</div>
                                 <ChevronRightIcon
                                    sx={{
                                       transition: ".3s all ease-in-out",
                                       opacity: chevron3,
                                       marginLeft: margin3,
                                    }}
                                 />
                              </div>
                           </button>
                        </a>
                     </MenuItem>
                     <MenuItem sx={{ backgroundColor: 'black' }}>
                        <a href={`${baseUrl}/events/west22/top-innovators`} style={{ width: '100%' }}>
                           <button
                              onMouseOver={(e) => {
                                 setChevron4(1);
                                 setMargin4("1.5vw");
                                 
                              }}
                              onMouseLeave={() => {
                                 setChevron4(0);
                                 setMargin4("0vw");
                              }}
                              className="dark-btn"
                              style={{
                                 marginRight: "1rem",
                                 fontWeight: "900",
                                 fontSize: "18px",
                                 width: '100%'
                              }}
                           >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                 <ChevronRightIcon sx={{ opacity: "0" }} />
                                 <div>Present</div>{" "}
                                 <ChevronRightIcon
                                    sx={{
                                       transition: ".3s all ease-in-out",
                                       opacity: chevron4,
                                       marginLeft: margin4,
                                    }}
                                 />
                              </div>
                           </button>
                        </a>
                     </MenuItem>
                  </Menu>
               </Box>
               <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  sx={{
                     flexGrow: 1,
                     display: {
                        xs: "flex",
                        md: "none",
                        fontFamily: "Poppins, sans-serif !important",
                     },
                  }}
               >
                  <div style={{ display: "flex", alignItems: "center" }}>
                     <a href="https://youngstartup.com/upcoming"><img
                        alt='vsd' src="/assets/events/west22/VSDCLogoWhite-01.png"
                        style={{ objectFit: "scale-down", maxWidth: "170px" }}
                     /></a>
                     <div
                        style={{
                           borderLeft: "1px solid white",
                           marginRight: "15px",
                           height: "80%",
                        }}
                     ></div>
                     <div style={{ display: "flex", flexDirection: "column" }}>
                        <h5
                           style={{
                              fontFamily: "Poppins, sans-serif !important",
                              fontSize: ".65rem",
                              margin: "0 !important",
                           }}
                        >
                           March 29-31, 2022
                        </h5>
                        <h5 style={{ fontSize: ".65rem", margin: "0 !important" }}>
                           Online
                        </h5>
                     </div>
                  </div>
               </Typography>
               <Box
                  sx={{
                     flex: 1,
                     ml: 15,
                     display: { xs: "none", md: "flex", justifyContent: "left" },
                  }}
               >
                  {pages.map((page) => (
                     <a href={page.link}>
                        <Button
                           key={page.name}
                           onClick={handleCloseNavMenu}
                           sx={{
                              mr: 2,
                              color:
                                 window.location.href === page.link ? "#B714E9" : "white",
                              display: "block",
                              fontFamily: "Poppins, sans-serif !important",
                              border:
                                 page.name === "matchmaking" ? "1px solid #B714E9" : "",
                              borderRadius: page.name === "matchmaking" && "15px",
                              "&:hover": { color: "purple" },
                           }}
                        >
                           {page.name}
                        </Button>
                     </a>
                  ))}
               </Box>

               <Box
                  sx={{ flex: 1, display: window.innerWidth < 1000 ? "none" : "" }}
               >
                  <div
                     style={{ display: "flex", gap: "10px", justifyContent: "right" }}
                  >
                     <a href={`${baseUrl}/events/west22/registration`}>
                        {/* <Button className="dark-btn" sx={{borderRadius:'0px', backgroundColor:'#7C2387', fontFamily: 'Poppins, sans-serif !important', "&:hover":{backgroundColor:'#7C2387'}}} variant="contained">Register</Button> */}
                        <button
                           onMouseOver={(e) => {
                              setChevron(1);
                              setMargin1("1.5vw");
                              
                           }}
                           onMouseLeave={() => {
                              setChevron(0);
                              setMargin1("0vw");
                           }}
                           className="dark-btn"
                           style={{
                              marginRight: "1rem",
                              fontWeight: "900",
                              fontSize: "18px",
                           }}
                        >
                           <div style={{ display: "flex", alignItems: "center" }}>
                              <ChevronRightIcon sx={{ opacity: "0" }} />
                              <div>Register</div>
                              <ChevronRightIcon
                                 sx={{
                                    transition: ".3s all ease-in-out",
                                    opacity: chevron,
                                    marginLeft: margin1,
                                 }}
                              />
                           </div>
                        </button>
                     </a>
                     {!window.location.href.includes("top-innovators") && <a href={`${baseUrl}/events/west22/top-innovators`}>
                        {/* <Button className="dark-btn" sx={{borderRadius:'0px', backgroundColor:'#7C2387', fontFamily: 'Poppins, sans-serif !important', "&:hover":{backgroundColor:'#7C2387'}}} variant="contained">Register</Button> */}
                        <button
                           onMouseOver={(e) => {
                              setChevron2(1);
                              setMargin2("1.5vw");
                              
                           }}
                           onMouseLeave={() => {
                              setChevron2(0);
                              setMargin2("0vw");
                           }}
                           className="dark-btn"
                           style={{
                              marginRight: "1rem",
                              fontWeight: "900",
                              fontSize: "18px",
                           }}
                        >
                           <div style={{ display: "flex", alignItems: "center" }}>
                              <ChevronRightIcon sx={{ opacity: "0" }} />
                              <div>Present</div>
                              <ChevronRightIcon
                                 sx={{
                                    transition: ".3s all ease-in-out",
                                    opacity: chevron2,
                                    marginLeft: margin2,
                                 }}
                              />
                           </div>
                        </button>
                     </a>}
                  </div>
                  <Menu
                     sx={{ mt: "45px" }}
                     id="menu-appbar"
                     anchorEl={anchorElUser}
                     anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     keepMounted
                     transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                     }}
                     open={Boolean(anchorElUser)}
                     onClose={handleCloseUserMenu}
                  >
                     {settings.map((setting) => (
                        <MenuItem key={setting} onClick={handleCloseUserMenu}>
                           <Typography textAlign="center">{setting}</Typography>
                        </MenuItem>
                     ))}
                  </Menu>
               </Box>
            </Toolbar>
         </Container>
      </AppBar>
   );
};
