import { NavBar, FooterNew } from "../components/index";
import Paper from "@mui/material/Paper";

export const Unsubscribe = () => {
   document.title = "youngStartup Ventures | Unsubscribe";

   return (
      <>
         <NavBar />
         <h1
            style={{
               color: "white",
               fontWeight: "900",
               textAlign: "center",
               backgroundColor: "black",
               margin: "0",
               padding: window.innerWidth < 769 ? "1%" : "2%",
            }}
         >
            UNSUBSCRIBE
         </h1>
         <Paper elevation={3} sx={{ margin: "2.5% auto", maxWidth: "1000px", p: 2, display: 'flex', justifyContent: 'center' }}>
            <iframe title='form' src="https://docs.google.com/forms/d/e/1FAIpQLSd2YIz86KYPkxwA2SZekGGfqxZ3sFCvdhrrLTjKU_bkcIqmAA/viewform?embedded=true" width={window.innerWidth < 900 ? "300px" : "640px"} height={"554"} frameBorder="0">Loading&#8230;</iframe>
         </Paper>
         <FooterNew />
      </>
   );
};
