
export const convertBase64 = (file) => {
   return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
         resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
         reject(error);
      };
   });
};

export const getMonthAndDay = (date) => {
   return new Date(date).toLocaleDateString("en-US", { month: 'long', day: 'numeric' })
}
export const getMonthAndDayAndWeekday = (date) => {
   return new Date(date).toLocaleDateString("en-US", { month: 'long', day: 'numeric', weekday: 'long' })
}

export const getTimeOf = (date) => {
   return new Date(date).toLocaleTimeString("en-US", { hour12: true, hour: "2-digit", minute: "2-digit" })
}

export const getNextDay = date => {
   var ms = new Date(date).getTime() + (1000 * 60 * 60 * 24);
   let newDate = new Date(ms);
   return getMonthAndDay(newDate)
}

export const unsetLocalTok = (tok) => {
   localStorage.removeItem("tok");
};

// gets params from URL
export const getQueryParameterByName = (name, url = window.location.href) => {
   name = name.replace(/[[\]]/g, "\\$&");
   var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
   if (!results) return null;
   if (!results[2]) return "";
   return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export const isElementVisible = (el) => {
   var rect = el.getBoundingClientRect(),
      vWidth = window.innerWidth || document.documentElement.clientWidth,
      vHeight = window.innerHeight || document.documentElement.clientHeight,
      efp = function (x, y) { return document.elementFromPoint(x, y) };

   // Return false if it's not in the viewport
   if (rect.right < 0 || rect.bottom < 0
      || rect.left > vWidth || rect.top > vHeight)
      return false;

   // Return true if any of its four corners are visible
   return (
      el.contains(efp(rect.left, rect.top))
      || el.contains(efp(rect.right, rect.top))
      || el.contains(efp(rect.right, rect.bottom))
      || el.contains(efp(rect.left, rect.bottom))
   );
}