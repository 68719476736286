export const testimonials = [
  {
    name: "Carl Berke",
    role: "Founder",
    headshot: '/assets/media/testimonials/Carl_Berke.png',
    company: "Berke International Group",
    testimonial:
      "The interaction between entrepreneurs, CEOs, the venture community, and investors is an extraordinary, strong, very productive mix. No conference brings it to bear like this youngStartup Ventures conference does.",
  },
  {
    name: "Dan Burstein",
    role: "Managing Partner",
    headshot: "/assets/media/testimonials/Dan_Burstein.png",
    company: "Millenium Technology",
    testimonial:
      "I have participated in numerous youngStartup conferences over the last decade, and always find them to be a highly interactive, multidimensional way to think out loud, learn, understand emerging trends, and network with the entrepreneurial community. Joe Benjamin and his youngStartup forums deserve the attention of those who seek to understand the early stage venture ecosystem.",
  },
  {
    name: "Patrick Chung",
    role: "CEO",
    headshot: "/assets/media/testimonials/patrick_chung-150x150.png",
    company: "Zeerow",
    testimonial:
      "Today’s summit was fantastic. We are located in the West Coast, and it’s always good to meet people on the East Coast to build new relationships. I’ve been able to meet amazing fellow investors as well as entrepreneurs. Meeting people is so very important, and that kind of  dynamic is something I feel here.  Very impressed  – I would love to continue to participate in youngStartup Ventures summits.",
  },
  {
    name: "Renee Compton Ryan",
    role: "VP",
    headshot: "/assets/media/testimonials/renee_ryan-cropped-250x241-1-150x150.png",
    company: "Venture Investments",
    testimonial:
      "Venture Summit West 2016 was a great two-day event – I connected with new companies and new investors in a super-positive, engaging environment. Hope to come back next year!",
  },
  {
    name: "Konstantine A. Drakonakis",
    role: "PE // Director",
    headshot: "/assets/media/testimonials/konstantine_drakonakis-150x150.png",
    company: "LaunchCapital, LLC",
    testimonial:
      "The youngStartup Ventures conference was well structured, with a very high bar for presenters, which is the major draw for VC’s to consider when selecting events.",
  },
  {
    name: "Walter Greenblatt",
    role: "CFO",
    headshot: "/assets/media/testimonials/walter_greenblatt-150x150.png",
    company: "Hygeia Therapeutics",
    testimonial:
      "Looking for investors with a specific value proposition – in other words a good fit – is a needle in a haystack. And there were several needles in the haystack at the youngStartup Ventures conference.",
  },
  {
    name: "Dave Gwozdz",
    role: "CFO",
    headshot: "/assets/media/testimonials/dave_gwozdz-150x150.png",
    company: "Mojiva, Inc.",
    testimonial:
      "We first took advantage of youngStartUp when we were just that – a three-month-old company, with three founders at the kitchen table. We presented, and youngStartup Ventures led us directly to investors that participated in both our Series A and Series B rounds. Their conferences work.",
  },
  {
    name: "Dave Kirkpatrick",
    role: "Founder and Managing Director",
    headshot: "/assets/media/testimonials/dave_kirkpatrick-250x250-1-150x150.png",
    company: "SJF Ventures",
    testimonial:
      "The NY Venture Summit has been a great place to find quality growth companies. For example, I made a great connection on the Cleantech Panel with Trailblazer Capital which resulted in our leading the Series B financing for their portfolio company, EnTouch Controls, who were one of the featured presenters.",
  },
  {
    name: "Noah Kroloff",
    role: "Managing Partner",
    headshot: "/assets/media/testimonials/noah_kroloff-150x150.png",
    company: "NGN Capital",
    testimonial:
      "It’s impressive that companies can present what they’re doing in a very short period of time –  it’s a very efficient way to hear about a lot of companies quickly. The panels have good panel members addressing a lot of good issues. It’s been a good day for everybody.",
  },
  {
    name: "Igal Ladabaum",
    role: " Co-Founder",
    headshot: "/assets/media/testimonials/Igal_Color_hospi-243x250-1-150x150.png",
    company: "Hospi",
    testimonial:
      "We connected with a private investor who rounded out our convertible note offering.",
  },
  {
    name: "Cora Leibig",
    role: "Founder & CEO",
    headshot: "/assets/media/testimonials/cl.png",
    company: "Chromatic 3D Materials",
    testimonial:
      "The investors I met at the Venture Summit, and their connections, led to an over-subscribed investment round.",
  },
  {
    name: "Mary Lincoln Campbell",
    role: "Managing Director",
    headshot: "/assets/media/testimonials/campbell1-e1536836810930.png",
    company: "EDF Ventures",
    testimonial:
      "The quality of the feedback given to entrepreneurs by investors prior to “showtime” was unparalleled in my experience. I highly recommend this conference.",
  },
  {
    name: "Dr. Douglas Lind",
    role: "Managing Partner",
    headshot: "/assets/media/testimonials/douglas_lind-150x150.png",
    company: "GBP Capital",
    testimonial:
      "It’s a great venue for networking, first and foremost. This conference introduced us to a number of people that we would never have seen otherwise, as well as collaborations with our portfolio companies and other portfolio companies. There is good energy, and a very constructive environment.",
  },
  {
    name: "Peter Longo",
    role: "Senior Managing Director of Investments",
    headshot: "/assets/media/testimonials/PeterLongo-e1537458872808-150x150.png",
    company: "Connecticut Innovations",
    testimonial:
      "The collection of the quality of the companies and the amount of investors make your events second to none.",
  },
  {
    name: "Daniel J. Schultz,",
    role: "Co-Founder & Managing Director",
    headshot: "/assets/media/testimonials/daniel_schultz.png",
    company: "DFJ Gotham Ventures",
    testimonial:
      "The quality of companies was outstanding, and the presentations strong. I’m looking forward to following up with a number of presenting companies, as well as entrepreneurs that I spoke to.",
  },
  {
    name: "Wolf Shlagman",
    role: "CEO",
    headshot: "/assets/media/testimonials/ext-e1548679954870-1-150x150.png",
    company: "Consult a Doctor",
    testimonial:
      "I am impressed with the quality of folks who are real venture capitalists, managing private equity. Folks actively looking for opportunities.",
  },
  {
    name: "Mina Sooch",
    role: "General Partner",
    headshot: "/assets/media/testimonials/mina_sooch-150x150.png",
    company: "Apjohn Ventures",
    testimonial:
      "The most impressive aspect is the ratio of venture capitalists to entrepreneurs. There are at least 50 venture capitalists walking around looking at deals, which is a really healthy ratio for the entrepreneurs, because at the end of the day it’s all about them connecting with us…The quality of presenters was very high, and the CEOs are well prepared and knowledgeable about what it’s going to take to raise money. They’re prepared for the long haul to meet and make relationships with the venture community.",
  },
  {
    name: "Jeanne Sullivan",
    role: "Partner",
    headshot: "/assets/media/testimonials/jeanne_sullivan.png",
    company: "Starvest Partners",
    testimonial:
      "I’m amazed by the large crowd and great enthusiasm in the room…a great opportunity as an entrepreneur.",
  },
  {
    name: "Ali Tehrani",
    role: "CEO",
    headshot: "/assets/media/testimonials/ali_tehrani.png",
    company: "Zymeworks",
    testimonial:
      "The decision makers are here. And I like the fact that West Coast companies are coming here, not just East Coast. Being able to tell our story to a large audience and get our name out there is what we, like any small company, really need.",
  },
  {
    name: "Sonali Vijayavargiya",
    role: "Founder & Managing Director",
    headshot: "/assets/media/testimonials/Vijayavargiya_Sonali-e1547543408947-150x150.png",
    company: "Augment Ventures",
    testimonial:
      "Key deal-making venue. We’ve invested in three companies that were recipients of Top Innovator awards at previous Venture Summits – RayVio, Fluid Screen and Iteros.",
  },
];
