import Paper from "@mui/material/Paper";
import Stack from '@mui/material/Stack';

export const FeaturedSpeakers = () => {

   const speakerArr = [
      { name: "Peter Thiel", title: "Co-Founder", company: "PayPal", logo: "/assets/media/paypal-logo-e1555328184658.webp", headshot_url: "/assets/media/peterThiel416x416-e1555325160191.webp" },
      { name: "Uri Levine", title: "Co-Founder", company: "Waze", logo: "/assets/media/waze-e1555327898997.webp", headshot_url: "/assets/media/UriLevine-e1555327978199.webp" },
      { name: "Bob Metcalfe", title: "Inventor of Ethernet", company: "Founder of 3com", logo: "/assets/media/1200px-3com-old-logo.svg_-e1618234072568.webp", headshot_url: "/assets/media/MetcalfeBluefeaturedSpeaker-e1599676506781.webp" },
      { name: "Jeff Hoffman", title: "Co-Founder", company: "Priceline", logo: "/assets/media/Priceline.webp", headshot_url: "/assets/media/Jeff-Hoffman-Headshot-e1594645291443.webp" },
      { name: "Ram Shiram", title: "Founding Board Member", company: "Google", logo: "/assets/media/sherpalo-logo_dark-bg-e1555328094952.webp", headshot_url: "/assets/media/RamShiram2.webp" },
      { name: "Eric Ly", title: "Co-Founder, CEO", company: "LinkedIn", logo: "/assets/media/Linkedin-Logo.webp", headshot_url: "/assets/media/eric-ly 1.png" },
      { name: "Nick Sears", title: "Co-Founder", company: "Android", logo: "/assets/media/logo192.png", headshot_url: "/assets/media/nick-sears.png" },

   ]

   const SpeakerCard = ({ speaker }) => {
      return (
         <Paper elevation={15} sx={{ p: 2, flex: 1, maxWidth: '225px !important', minWidth: "225px", justifyContent: 'space-between', height: "396px" }}>
            <Stack sx={{ display: 'flex', textAlign: 'center', alignItems: 'stretch', justifyContent: 'space-between', height: '100%' }}>
               <img alt={speaker.name} src={speaker.headshot_url} style={{ width: '100%', height: 'auto' }} />
               <b>{speaker.name}</b>
               <div>{speaker.title},</div>
               <div>{speaker.company}</div>
               <img alt={speaker.company} src={speaker.logo} style={{ width: '200px', height: '75px', objectFit: 'scale-down' }} />
            </Stack>
         </Paper>
      )
   }

   return (
      <Stack>
         <h1 style={{ textAlign: 'center', fontWeight: '900', margin: '7.5vh' }}>PREVIOUSLY <span style={{ color: '#FF2945' }}>FEATURED</span> KEYNOTE SPEAKERS</h1>
         <Stack sx={{ mb: 10, display: 'flex' }}>
            <div style={{ display: 'flex', justifyContent: 'center', gap: window.innerWidth < 900 ? "15px" : '2%', padding: "0% 15%", flexDirection: window.innerWidth < 1000 && "column", alignItems: 'center' }}>
               {speakerArr.map((speaker, i) => {
                  if (i < 4) return <SpeakerCard speaker={speaker} key={i} />
                  return <div key={i}></div>
               })}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: window.innerWidth < 900 ? "15px" : '2%', marginTop: '2.5vh', padding: "0% 15%", flexDirection: window.innerWidth < 1000 && "column", alignItems: 'center' }}>
               {speakerArr.map((speaker, i) => {
                  if (i > 3) return <SpeakerCard speaker={speaker} key={i} />
                  return <div key={i}></div>
               })}
            </div>
         </Stack>
      </Stack>
   );
};
