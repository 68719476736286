import { NavBar, Footer } from "../components/index";

export const About = () => {
   document.title = "youngStartup Ventures | About";

   return (
      <>
         <NavBar />
         <div style={{ display: "flex", flexDirection: "column" }}>
            <div
               style={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: "5rem",
                  boxShadow: "0px 0px 25px 10px #b1b1b1",
                  fontWeight: "900",
                  fontFamily: "Roboto Condensed, sans-serif !important",
               }}
            >
               <span style={{ marginRight: "1rem" }}>ABOUT</span>
               <span style={{ color: "#FF2945" }}>US</span>
            </div>
            <div
               className="about-text-container"
               style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center !important",
                  textAlign: "center !important",
               }}
            >
               <div
                  className="about-text"
                  style={{ margin: "7.5rem", textAlign: "center" }}
               >
                  youngStartup Venture’s summits connect the most innovative,
                  promising, venture-backed, emerging growth and early stage companies
                  with active investors, investment bankers, advisors and corporate
                  professionals. Representatives from 3M, American Express, Capital
                  One, Dow, JetBlue, Lockheed Martin, Motorola, SalesForce, and many
                  others attend our summits. The summits feature insightful
                  discussions, the newest technologies and latest trends currently
                  shaping the future. YSV understands how important it is to meet the
                  right people and network with like-minded individuals. Our summits
                  ensure that only the most cutting edge companies and relevant
                  investors are present. We work hard to make sure that you have the
                  opportunity to mingle with those in the know to forge the
                  connections you need to succeed.
               </div>
               <div className="about-img" style={{ paddingRight: "5rem" }}>
                  <img
                     alt='product-school'
                     src="/assets/media/product-school-nOvIa_x_tfo-unsplash-scaled.png"
                     className="img-fluid shadow-lg p-3 mb-5 mr-5 mt-5 bg-white rounded"
                     style={{ borderRadius: "10px", filter: "grayscale(100%)" }}
                  />
               </div>
            </div>
         </div>
         <Footer />
      </>
   );
};
