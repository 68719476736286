import Alert from '@mui/material/Alert';
import { Link } from "@mui/material";
import { NavBarNYVS23, FooterNYVS23 } from '../components';

export const FormSuccess = () => {
   document.title = "youngStartup Ventures | Form Success";

   return (
      <div
         style={{
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
         }}
      >
         <NavBarNYVS23 />
         <div
            style={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               flexDirection: 'column',
               gap: '50px',
               height: "60vh",
            }}
         >
            <h2 style={{ color: 'white' }}>Thank you for letting us know!</h2>
            <Alert variant="filled" severity="success">
               We have received your information
            </Alert>
            <Link href="https://youngstartup.com/upcoming-event" style={{ fontSize: '2rem' }}>Explore our upcoming event</Link>
         </div>
         <FooterNYVS23 />
      </div>
   );
};
