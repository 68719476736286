import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";

const style = {
   width: "100%",
   maxWidth: 360,
   bgcolor: "background.paper",
   margin: 'auto',
};

const Item = styled(Paper)(({ theme }) => ({
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: "center",
   color: 'white',
}));

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export const RegistrationPricing = () => {
   const [value, setValue] = React.useState(0);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   return (
      <Box sx={{ width: "100%" }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
               value={value}
               centered
               onChange={handleChange}
               aria-label="basic tabs example"
            >
               <Tab label="Startups" {...a11yProps(0)} />
               <Tab label="Investors" {...a11yProps(1)} />
               <Tab label="General Attendees" {...a11yProps(2)} />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <Grid container spacing={3} >
               <Grid item xs={4} >
                  <Item sx={{ backgroundColor: '#40B1E5!important' }}>
                     <h2>Founder Pass</h2>
                     <h4>Now only $395</h4>
                     <h4><strike>$795</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#40B1E5 !important' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="One Founder Pass" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Food and Drink Available for purchase" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#228EBF' }}>
                     <h2>VIP Pass</h2>
                     <h4>Now only $495</h4>
                     <h4><strike>$895</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#228EBF' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="One VIP Pass" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Early Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Snacks and Drinks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Continental Breakfast" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Lunch" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Registration Desk" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Seating | Main Stage" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#126A92' }}>
                     <h2>Platinum Package</h2>
                     <h4>Now only $1295</h4>
                     <h4><strike>$1695</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#126A92' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText><b>Three VIP passes</b></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }} >
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Early Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Snacks and Drinks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Continental Breakfast" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Lunch" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Registration Desk" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Seating | Main Stage" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Hard Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discount for Additional Passes" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
            </Grid>
         </TabPanel>
         <TabPanel value={value} index={1}>
            <Grid container spacing={2}>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#34C48B' }}>
                     <h2>Founder Pass</h2>
                     <h4>Now only $495</h4>
                     <h4><strike>$895</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#34C48B' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="One Founder Pass" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Food and Drink Available for purchase" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#189B67' }}>
                     <h2>VIP Pass</h2>
                     <h4>Now only $595</h4>
                     <h4><strike>$995</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#189B67' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="One VIP Pass" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Early Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Snacks and Drinks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Continental Breakfast" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Lunch" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Registration Desk" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Seating | Main Stage" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#007748' }}>
                     <h2>Platinum Package</h2>
                     <h4>Now only $1595</h4>
                     <h4><strike>$1995</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#007748' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText><b>Three VIP passes</b></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Early Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Snacks and Drinks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Continental Breakfast" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Lunch" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Registration Desk" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Seating | Main Stage" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Hard Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discount for Additional Passes" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
            </Grid>
         </TabPanel>
         <TabPanel value={value} index={2}>
            <Grid container spacing={2}>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#7956C7' }}>
                     <h2>Founder Pass</h2>
                     <h4>Now only $695</h4>
                     <h4><strike>$1095</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#7956C7' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="One Founder Pass" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Food and Drink Available for purchase" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#643DB8' }}>
                     <h2>VIP Pass</h2>
                     <h4>Now only $795</h4>
                     <h4><strike>$1195</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#643DB8' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="One VIP Pass" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Early Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Snacks and Drinks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Continental Breakfast" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Lunch" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Registration Desk" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Seating | Main Stage" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: '#491F9A' }}>
                     <h2>Platinum Package</h2>
                     <h4>Now only $2195</h4>
                     <h4><strike>$2595</strike></h4>
                     <List sx={{ ...style, backgroundColor: '#491F9A' }} component="nav" aria-label="mailbox folders">
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText><b>Three VIP passes</b></ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Opening Cocktail Reception" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to all VC Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Startup Innovator Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Web App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Access to Mobile App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Early Access to 1 on 1 Scheduling App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Dedicated Networking Breaks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Digital Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Snacks and Drinks" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Continental Breakfast" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Lunch" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Registration Desk" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="VIP Seating | Main Stage" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discounted Hotel Rates (while they last)" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Hard Copy of Conference Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: 'center' }}>
                           <ListItemText primary="Discount for Additional Passes" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
            </Grid>
         </TabPanel>
      </Box>
   );
};
