import { useEffect } from "react";
import { NavBarNYVS23, FooterNYVS23 } from "../components/index";
import Paper from "@mui/material/Paper";

export const SponsorFileUpload = () => {
   document.title = "youngStartup Ventures | Sponsor File Upload";

   useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: "536350",
               formId: "01b17cc9-55f0-4304-b272-4460e02c0c7f",
               target: "#sponsorForm",
            });
         }
      });
   }, []);

   return (
      <div style={{ backgroundColor: 'black' }}>
         <NavBarNYVS23 />

         <h1
            id="form"
            style={{
               color: "white",
               backgroundColor: "black",
               textAlign: "center",
               fontWeight: "900",
               paddingTop: "5vh",
               paddingBottom: "5vh",
               marginBottom: "0",
            }}
         >
            Sponsor Details
         </h1>
         <Paper
            elevation={15}
            id="paper"
            sx={{ margin: "2.5% auto", maxWidth: "1000px" }}
         >
            <div
               style={{ width: "100%", display: "flex", justifyContent: "center" }}
            ></div>
            <div
               id="sponsorForm"
               style={{
                  paddingTop: "2.5vh",
                  paddingRight: "2.5vw",
                  paddingLeft: "2.5vw",
               }}
            />
         </Paper>
         <FooterNYVS23 />

      </div>
   );
};
