import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AirIcon from "@mui/icons-material/Air";
import BiotechIcon from "@mui/icons-material/Biotech";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { experimentalStyled as styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
export const FeaturedIndustries = () => {
  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),
    fontFamily: "Roboto Condensed, sans-serif",
    textAlign: "center",
    height: "100%",
    width:'100%',
    color: theme.palette.text.primary,
    marginRight:'1rem',
  }));
  return (
    <div  style={{ overflow: "hidden" }}>
      <h1 style={{textAlign:'center', paddingBottom:'5vh'}}>Featured Industries</h1>
      <Grid
        container
        columns={5}
        alignItems="stretch"
        align="stretch"
        className="tracks-row text-center industry-cards"
        style={{ paddingRight: "15vw", paddingLeft: "15vw", justifyContent:'space-around', paddingBottom:'5vh', gap:window.innerWidth < 1000 ? "15px" : '1%'}}
      >
        <Grid item sx={{width:'225px'}}>
          <Item elevation={15} className=" track foo turn-me-grey">
            {/* <div  css={{color:'#1acd7b'}} className="icon"><i className="fas fa-cogs"></i></div> */}
            <div className="tracks-icon-red">
              <PowerSettingsNewIcon />
            </div>

            <div className="track-title highlight-color">
              <h4>Tech</h4>
            </div>
            <div className="track-list">
              <ul>
                <li className="underline">Mobile</li>
                <li className="underline">Internet/Digital Media</li>
                <li className="underline">IoT</li>
                <li className="underline">Security</li>
                <li className="underline">Software/IT</li>
                <li className="underline">EdTech</li>
                <li>Robotics</li>
              </ul>
            </div>
          </Item>
        </Grid>
        {/* <div className="col-md-4 track foo">
            <div  style={{color:'#1acd7b'}}  ><i className="fas fa-mobile-alt track-logo theme-color-primary"></i></div>
          <div className="track-title highlight-color">
             <h4>Consumer Products</h4>
          </div>
            <div className="track-list">
                <ul>
                    <li>Electronics</li>
                    <li>Sports</li>
                    <li>Tourism</li>
                    <li>Leisure/Entertainment</li>
                    <li>Fashion/Apparel</li>
                    <li>FoodTech</li>
                    <li>Wellness</li>
                </ul>
            </div>
        </div>  */}
        <Grid item sx={{width:'225px'}}>
          <Item elevation={15} className="track foo">
            <div className="tracks-icon-red">
              <AccountBalanceIcon />
            </div>
            <div className="track-title highlight-color">
              <h4>FinTech</h4>
            </div>
            <div className="track-list">
              <ul>
                <li className="underline">Banking</li>
                <li className="underline">Blockchain</li>
                <li className="underline">Insurtech</li>
                <li className="underline">Personal Finance</li>
                <li className="underline">Payments</li>
                <li>Predictive Analytics</li>
              </ul>
            </div>
          </Item>
        </Grid>
        <Grid item sx={{width:'225px'}}>
          <Item elevation={15} className="track foo turn-me-grey">
            {/* <div  style={{color:'#1acd7b'}} className="icon"><i className="fas fa-globe-americas"></i></div> */}
            <div className="tracks-icon-red">
              <AirIcon />
            </div>
            <div className="track-title highlight-color">
              <h4>CleanTech</h4>
            </div>
            <div className="track-list">
              <ul>
                <li className="underline">Energy Efficiency</li>
                <li className="underline">Energy Generation</li>
                <li className="underline">Energy Infrastructure</li>
                <li className="underline">Energy Storage</li>
                <li className="underline">Transportation</li>
                <li className="underline">Water &amp; Wastewater</li>
                <li>AgriTech</li>
              </ul>
            </div>
          </Item>
        </Grid>
        <Grid item sx={{width:'225px'}}>
          <Item elevation={15} className="track foo">
            <div className="tracks-icon-red">
              <HealthAndSafetyIcon />
            </div>
            <div className="track-title highlight-color">
              <h4>MedTech</h4>
            </div>
            <div className="track-list">
              <ul>
                <li className="underline">Medical Devices</li>
                <li className="underline">Diagnostics</li>
                <li className="underline">Monitoring</li>
                <li className="underline">Wearables</li>
                <li className="underline">AI Solutions</li>
                <li>Medical AR</li>
              </ul>
            </div>
          </Item>
        </Grid>
        <Grid item sx={{width:'225px'}}>
          <Item elevation={15} className="track foo">
            {/* <div  style={{color:'#1acd7b'}} className="icon"><i className="fas fa-stethoscope"></i></div> */}
            <div className="tracks-icon-red">
              <BiotechIcon />
            </div>
            <div className="track-title highlight-color">
              <h4>Life Sciences / <div>Healthcare</div></h4>
            </div>
            <div className="track-list">
              <ul>
                <li className="underline">BioTech</li>
                <li className="underline">Drug Discovery</li>
                <li className="underline">Healthcare IT</li>
                <li className="underline">Pharma</li>
                <li>Healthcare</li>
              </ul>
            </div>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};
