import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import { FooterNYVS23, NavBarNYVS23 } from '../components';

export const VentureNurturing = () => {
   document.title = "youngStartup Ventures | Venture Nurturing";

   useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: "536350",
               formId: "366265ca-342e-48bb-a0f8-015c59cbbeee",
               target: "#ventureNurturingForm",
            });
         }
      });
   });
   return (
      <div style={{ backgroundColor: 'black' }}>
         <NavBarNYVS23 />

         <h1
            style={{
               textAlign: "center",
               backgroundColor: "black",
               color: "white",
               fontWeight: "900",
               paddingTop: "2.5vh",
               paddingBottom: "2.5vh",
            }}
         >
            VENTURE NURTURING™
         </h1>
         <Paper elevation={3} sx={{ margin: "2.5% auto", maxWidth: "1000px", p: 5 }}>
            <h2 style={{ textAlign: "center", fontWeight: "900" }}>
               Helping seed stage startups get where they need to be.
            </h2>
            <br />
            <h3 style={{ textAlign: "center", fontWeight: "900" }}>
               Win a FREE PASS to Emerging Tech Venture Summit
            </h3>
            <h3 style={{ textAlign: "center", fontWeight: "900" }}>
               Gain Visibility for your startup
            </h3>
            <br />
            <div style={{ textAlign: "center", fontWeight: "900" }}>
               This unique program provides{" "}
               <span style={{ color: "blue" }}>25 qualified</span> startups free
               tickets to attend and network at Emerging Tech Venture Summit.
            </div>
            <br />
            <div style={{ paddingRight: '2.5vw', paddingLeft: '2.5vw' }}>
               <div>
                  The opportunity for VentureNurturing startups is <b>totally free.</b>
               </div>
               <br />
               <div>To qualify, your startup or the startup you nominate must meet the
                  following criteria:</div>
               <div style={{ paddingRight: '2.5vw', paddingLeft: '2.5vw' }}>
                  <div>1. Have not raised any funding to date</div>
                  <div>2. Have less than
                     $100K annual revenue</div>
                  <div>3. Have not previously attended a youngStartup
                     Ventures conference</div>
               </div>
               <br />
               <div>Startups selected will be awarded one Founder Pass to
                  <b> Emerging Tech Venture Summit.</b></div>
               <br />
               <b>Founder Pass includes:</b>
               <div>Access to all VC Panels, Access to Startup Innovator Presentations, Access to 1 on 1 Scheduling, Dedicated Networking Breaks, Digital Copy of Conference Guide and more.</div>
               <br />
               <div>* Winners will be chosen at youngStartup’s discretion.</div>
               <br />
               <b>TO APPLY:</b>
               <div>1. Make a
                  post on Twitter with why you think you should be selected and include
                  the following: #venturenurturing #ETVS23 @youngstartupven. </div>
               <div>2. Suggestion –
                  include a 60 second video of your elevator pitch in your tweet. </div><div>3. Fill
                     out the form below
               </div>
               <br />
               <b>Best of luck!</b>
            </div>
            <div id="ventureNurturingForm" style={{ paddingTop: "5vh" }} />
         </Paper>
         <FooterNYVS23 />

      </div>
   );
};
