import { svgs } from '../assets/svgs/svgs';
import styles from './FooterNYVS23.module.css';
import { tracking } from '../utils/tracking';
const socialMediaLinks = [
   {
      name: 'linkedin',
      icon: svgs.linkedin,
      link: "https://www.linkedin.com/company/363980",
      buttonId: 297
   },
   {
      name: 'flickr',
      icon: svgs.fliker,
      link: "https://www.flickr.com/photos/youngstartup/",
      buttonId: 298
   },
   {
      name: 'youtube',
      icon: svgs.youtube,
      link: "https://www.youtube.com/user/youngStartup",
      buttonId: 299
   },
]

const aboutLinks = [
   { name: 'Home', link: 'https://youngstartup.com', buttonId: 258 },
   { name: 'Contact Us', link: 'https://youngstartup.com/contact', buttonId: 259 },
   { name: 'Testimonials', link: 'https://youngstartup.com/testimonials', buttonId: 260 },
   { name: 'Events', link: 'https://youngstartup.com/summits', buttonId: 261 },
   { name: 'FAQ', link: 'https://youngstartup.com/faq', buttonId: 339 },
]

const ventureSummitLinks = [
   { name: 'Startups', link: 'https://youngstartup.com/events/nyvs23/startups', buttonId: 262 },
   { name: 'Speakers', link: 'https://youngstartup.com/events/nyvs23/speakers', buttonId: 263 },
   { name: 'Partners', link: 'https://youngstartup.com/events/nyvs23/partners', buttonId: 264 },
   { name: 'Get your Ticket', link: 'https://youngstartup.com/events/nyvs23/registration', buttonId: 265 },
   { name: 'Volunteer', link: 'https://youngstartup.com/events/nyvs23/volunteer', buttonId: 337 },
   { name: 'Press', link: 'https://youngstartup.com/events/nyvs23/press', buttonId: 343 },
]

const ourEventsLinks = [
   {
      name: 'Miami Venture Summit', link: 'https://youngstartup.com/events/fvs22/', buttonId: 266
   },
   {
      name: 'Venture Summit West', link: 'https://youngstartup.com/events/summer22/', buttonId: 267
   },
   {
      name: 'Venture Summit Virtual Connect', link: 'https://events.youngstartup.com/events/vsvc21/', buttonId: 268
   },
   {
      name: 'New England Venture Summit', link: 'https://events.youngstartup.com/events/newengland2019/', buttonId: 269
   },
   {
      name: 'New York Venture Summit', link: 'https://events.youngstartup.com/events/newyork2019/', buttonId: 270
   },
];

export const FooterNYVS23 = () => {
   return (
      <footer className={styles.footer}>
         <div className={styles.mainFooter}>
            <div className={styles.logo}>
               {svgs.youngStartupsLogo}
               <h6>Where Innovation Meets Capital ™</h6>
            </div>
            <div className={styles.followUs}>
               <h6>Follow Us</h6>
               <div className={styles.icons}>
                  {socialMediaLinks.map(media =>
                     <div onClick={() => tracking(media.buttonId)} key={media.name}>
                        <a href={media.link} rel='noreferrer' target='_blank'>{media.icon}</a>
                     </div>
                  )}
               </div>
            </div>
            <div className={styles.about}>
               <h6>About</h6>
               <ul >
                  {aboutLinks.map(about =>
                     <li onClick={() => tracking(about.buttonId)} key={about.name}>
                        <a href={about.link}>{about.name}</a>
                     </li>
                  )}
               </ul>
            </div>
            <div className={styles.emergingTech}>
               <h6>Venture Summit</h6>
               <ul >
                  {ventureSummitLinks.map(expo =>
                     <li key={expo.name} onClick={() => tracking(expo.buttonId)}>
                        <a href={expo.link}>{expo.name}</a>
                     </li>
                  )}
               </ul>
            </div>
            <div className={styles.ourEvents}>
               <h6>Our Events</h6>
               <ul>
                  {ourEventsLinks.map(event =>
                     <li key={event.name} onClick={() => tracking(event.buttonId)}>
                        <a
                           href={event.link}
                           target='_blank'
                           rel='noreferrer'>{event.name}
                        </a>
                     </li>
                  )}
               </ul>
            </div>
         </div>
         <hr />
         <div className={styles.copyRights}>
            <h6>All Rights Reserved © 1999-2023 - youngStartup Ventures</h6>
         </div>
      </footer>
   );
};
