import { useState } from 'react';
import Button from '@mui/material/Button';

export const NewButton = ({ name, color, bgColor, borderColor, fontSize }) => {
   const [classes, setClasses] = useState('box')
   const [brightness, setBrightness] = useState("100%")
   return (
      <Button
         endIcon={
            <img src={color === 'white' ? "../../../../assets/events/etvs23/white-chevron.png" : "../../../../assets/events/etvs23/blue-chevron.png"} className={classes} alt="" />
         }
         onMouseEnter={() => { setClasses('box bounce-1'); setBrightness("90%") }}
         onMouseLeave={() => { setClasses('box'); setBrightness("100%") }}
         sx={{
            height: '100%',
            width: '100%',
            border: `1px solid ${borderColor}`,
            borderRadius: "25px",
            color: color,
            fontWeight: "900",
            background: bgColor,
            opacity: 1,
            fontSize: fontSize,
            filter: `brightness(${brightness})`,
            padding: '0.3em 1em',
            webkitBoxShadow: "5px 5px 5px -5px rgba(0, 0, 0 , 0.5)",
            mozBoxShadow: "5px 5px 5px -5px rgba(0, 0, 0 , 0.5)",
            boxShadow: "5px 5px 5px -5px rgba(0, 0, 0 , 0.5)",
            ':hover': {
               bgcolor: bgColor,
               color: color,
            },
         }}
      >
         <span style={{ filter: 'brightness(100%) !important', whiteSpace: 'nowrap' }}>{name}</span>
      </Button>
   );
};