import { useEffect } from 'react';
import Paper from "@mui/material/Paper";
import { FooterNYVS23, NavBarNYVS23 } from '../components';


export const AdvocateProgram = () => {
   document.title = "youngStartup Ventures | Advocate Program";

   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: '536350',
               formId: '994a0178-b435-4a0a-bd83-3c18d44ac81d',
               target: '#advocateProgram'
            })
         }
      });
   });
   return (
      <div style={{ backgroundColor: '#0f1a28' }}>
         <NavBarNYVS23 />
         <Paper elevation={3} sx={{ margin: "2.5% auto", maxWidth: "1000px" }}>
            <div id="advocateProgram" style={{ paddingTop: '2.5vh', paddingRight: '2.5vw', paddingLeft: '2.5vw' }} />
         </Paper>
         <FooterNYVS23 />

      </div>
   )
}