import { NavBar, SubscribeForm, LeadingInvestors, UpcomingSummit, Highlights, FeaturedSpeakers, FeaturedIndustries, Footer } from "../components/index";
export const Home = () => {
   document.title = "youngStartup Ventures | Home";

   return (
      <div
      >
         <NavBar />
         <div
            style={{
               position: "flex",
               width: "100%",
               height: 'fit-content',
               backgroundColor: "black"
            }}
         >
            <img alt='home' className="home-background-img" style={{ width: '100%' }} src="/assets/media/smiley.webp" />
            <video className="videoTag" autoPlay loop muted>
               <source
                  src={
                     "assets/media/homepagevideo.mp4"
                  }
                  type="video/mp4"
               />
            </video>
            <span
               className="tagline"
               style={{
                  color: "white",
                  fontSize: "3rem",
                  fontWeight: "900",
                  position: "absolute",
                  left: "50%",
                  whiteSpace: "nowrap",
                  top: "65%",
                  transform: "translate(-50%,-50%)",
               }}
            >
               Where Innovation Meets Capital &trade;
            </span>

         </div>
         <SubscribeForm id={`subscribeForm1`} />
         <LeadingInvestors />
         <UpcomingSummit />
         <Highlights />
         <FeaturedSpeakers />
         <FeaturedIndustries />
         <Footer />
      </div>
   );
};
