import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

const style = {
   width: "100%",
   maxWidth: 360,
   bgcolor: "background.paper",
   margin: "auto",
};

const Item = styled(Paper)(({ theme }) => ({
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: "center",
   color: "white",
   fontFamily: ""
}));

function TabPanel(props) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && (
            <Box sx={{ p: 3 }}>
               <Typography>{children}</Typography>
            </Box>
         )}
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
   };
}

export const VirtualRegistrationPricing = () => {
   const [value, setValue] = React.useState(0);
   const [scrollable, setScrollable] = useState("");
   const [centered, setCentered] = useState(true);
   const [founder, setFounder] = useState(false);
   const [vip, setVip] = useState(false);
   const [platinum, setPlatinum] = useState(false);

   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   useEffect(() => {
      if (window.innerWidth < 768) {
         setCentered(false);
         setScrollable("scrollable");
      } else {
         setCentered(true);
         setScrollable("");
      }
   }, []);

   return (
      <Box sx={{ width: "100%", color: 'white !important' }}>
         <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
               value={value}
               onChange={handleChange}
               centered={centered}
               variant={scrollable}
               aria-label="basic tabs example"
               scrollButtons="auto"
            >
               <Tab label="Startups" {...a11yProps(0)} />
               <Tab label="Investors" {...a11yProps(1)} />
               <Tab label="General Attendees" {...a11yProps(2)} />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <Stack className="registration-pricing-mobile" style={{ display: 'none' }} spacing={2}>
               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List
                     sx={{
                        ...style,
                        backgroundColor: "#40B1E5 !important",
                        textAlign: "center",
                        width: "75%",
                        borderRadius: "5px",
                     }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        founder === false ? setFounder(true) : setFounder(false)
                     }}
                  >
                     <h2>Founder Lite</h2>
                     <h4>Now only $497</h4>
                     <h4>
                        <strike>$597</strike>
                     </h4>
                     <div>More Info{founder === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {founder === true &&
                        <div>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary=" One Ticket" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                        </div>}
                  </List>
               </div>

               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{
                        ...style,
                        backgroundColor: "#228EBF",
                        width: "75%",
                        borderRadius: "5px",
                     }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        vip === false ? setVip(true) : setVip(false)
                     }}
                  >
                     <h2>Founder VIP</h2>
                     <h4>Now only $597</h4>
                     <h4>
                        <strike>$697</strike>
                     </h4>
                     <div>More Info{vip === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {vip === true &&
                        <div>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary=" One Ticket" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="One-on-One Networking" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Networking App" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Early Access to Networking App – 2 days before Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Powerful Matchmaking Engine" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="View Attendee Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Email Support" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="On-Demand Recordings – Add-on $100" />
                           </ListItem>
                        </div>}
                  </List>
               </div>

               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{
                        ...style,
                        backgroundColor: "#126A92",
                        width: "75%",
                        borderRadius: "5px",
                     }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        platinum === false ? setPlatinum(true) : setPlatinum(false)
                     }}
                  >
                     <h2>Founder Platinum</h2>
                     <h4>Now only $697</h4>
                     <h4>
                        <strike>$797</strike>
                     </h4>
                     <div>More Info{platinum === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {platinum === true &&
                        <div>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText>
                                 <b> Two Tickets</b>
                              </ListItemText>
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="One-on-One Networking" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Networking App" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Early Access to Networking App – 10 days before Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Powerful Matchmaking Engine" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="View Attendee Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Email Support" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Digital Event Guide" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Featured Company Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="On-Demand Recordings" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Phone Support" />
                           </ListItem>
                        </div>}
                  </List>
               </div>
            </Stack>
            <Grid className="registration-pricing-web" container spacing={3} sx={{ pt: 2, pr: 6, pl: 6, pb: 6 }}>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#40B1E5!important" }}>
                     <h2>Founder Lite</h2>
                     <h4>Now only $497</h4>
                     <h4>
                        <strike>$597</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#40B1E5 !important" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#228EBF" }}>
                     <h2>Founder VIP</h2>
                     <h4>Now only $597</h4>
                     <h4>
                        <strike>$697</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#228EBF" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 2 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings – Add-on $100" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#126A92" }}>
                     <h2>Founder Platinum</h2>
                     <h4>Now only $697</h4>
                     <h4>
                        <strike>$797</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#126A92" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText>
                              <b> Two Tickets</b>
                           </ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 10 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Digital Event Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Featured Company Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
            </Grid>
         </TabPanel>
         <TabPanel value={value} index={1}>
            <Stack className="registration-pricing-mobile" style={{ display: 'none' }} spacing={2}>
               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{ ...style, backgroundColor: "#34C48B", width: '75%', textAlign: 'center', borderRadius: '5px' }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        founder === false ? setFounder(true) : setFounder(false)
                     }}
                  >
                     <h2>Investor Lite</h2>
                     <h4>Now only $597</h4>
                     <h4>
                        <strike>$697</strike>
                     </h4>
                     <div>More Info{founder === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {founder === true &&
                        <>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary=" One Ticket" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                        </>}
                  </List>
               </div>
               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{ ...style, backgroundColor: "#189B67", textAlign: 'center', borderRadius: '5px', width: '75%' }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        vip === false ? setVip(true) : setVip(false)
                     }}
                  >
                     <h2>Investor VIP</h2>
                     <h4>Now only $697</h4>
                     <h4>
                        <strike>$797</strike>
                     </h4>
                     <div>More Info{vip === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {vip === true &&
                        <>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary=" One Ticket" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="One-on-One Networking" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Networking App" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Early Access to Networking App – 2 days before Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Powerful Matchmaking Engine" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="View Attendee Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Email Support" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="On-Demand Recordings – Add-on $100" />
                           </ListItem>
                        </>}
                  </List>
               </div>
               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{ ...style, backgroundColor: "#007748", width: '75%', textAlign: 'center', borderRadius: '5px' }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        platinum === false ? setPlatinum(true) : setPlatinum(false)
                     }}
                  >
                     <h2>Investor Platinum</h2>
                     <h4>Now only $797</h4>
                     <h4>
                        <strike>$897</strike>
                     </h4>
                     <div>More Info{platinum === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {
                        platinum === true &&
                        <>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText>
                                 <b> Two Tickets</b>
                              </ListItemText>
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="One-on-One Networking" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Networking App" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Early Access to Networking App – 10 days before Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Powerful Matchmaking Engine" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="View Attendee Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Email Support" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Digital Event Guide" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Featured Company Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="On-Demand Recordings" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Phone Support" />
                           </ListItem>
                        </>}
                  </List>
               </div>
            </Stack>
            <Grid className="registration-pricing-web" container spacing={3} sx={{ pt: 2, pr: 6, pl: 6, pb: 6 }}>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#34C48B" }}>
                     <h2>Investor Lite</h2>
                     <h4>Now only $597</h4>
                     <h4>
                        <strike>$697</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#34C48B" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#189B67" }}>
                     <h2>Investor VIP</h2>
                     <h4>Now only $697</h4>
                     <h4>
                        <strike>$797</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#189B67" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 2 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings – Add-on $100" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#007748" }}>
                     <h2>Investor Platinum</h2>
                     <h4>Now only $797</h4>
                     <h4>
                        <strike>$897</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#007748" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText>
                              <b> Two Tickets</b>
                           </ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 10 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Digital Event Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Featured Company Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
            </Grid>
         </TabPanel>
         <TabPanel value={value} index={2}>
            <Stack className="registration-pricing-mobile" style={{ display: 'none' }} spacing={2}>
               <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{ ...style, backgroundColor: "#7956C7", textAlign: 'center', width: '75%', borderRadius: '5px' }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        founder === false ? setFounder(true) : setFounder(false)
                     }}
                  >
                     <h2>Professional Lite</h2>
                     <h4>Now only $597</h4>
                     <h4>
                        <strike>$697</strike>
                     </h4>
                     <div>More Info{founder === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {founder === true && <>
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem> </>}
                  </List>
               </div>     <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{ ...style, backgroundColor: "#643DB8", textAlign: 'center', width: '75%', borderRadius: '5px' }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        vip === false ? setVip(true) : setVip(false)
                     }}
                  >
                     <h2>Professional VIP</h2>
                     <h4>Now only $697</h4>
                     <h4>
                        <strike>$797</strike>
                     </h4>
                     <div>More Info{vip === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {vip === true && <><ListItem sx={{ textAlign: "center" }}>
                        <ListItemText primary=" One Ticket" />
                     </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 2 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings – Add-on $100" />
                        </ListItem>
                     </>}
                  </List>
               </div>     <div
                  style={{
                     justifyContent: "center",
                     display: "flex",
                     textAlign: "center",
                  }}
               >
                  <List

                     sx={{ ...style, backgroundColor: "#491F9A", textAlign: 'center', width: '75%', borderRadius: '5px' }}
                     component="nav"
                     aria-label="mailbox folders"
                     onClick={() => {
                        platinum === false ? setPlatinum(true) : setPlatinum(false)
                     }}
                  >
                     <h2>Professional Platinum</h2>
                     <h4>Now only $797</h4>
                     <h4>
                        <strike>$897</strike>
                     </h4>
                     <div>More Info{platinum === false ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}</div>
                     {platinum === true &&
                        <>
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText>
                                 <b> Two Tickets</b>
                              </ListItemText>
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="One-on-One Networking" />
                           </ListItem>
                           <Divider />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Networking App" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Early Access to Networking App – 10 days before Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Powerful Matchmaking Engine" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="View Attendee Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Venture Panels" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to all Workshops" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Company Presentations" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Access to Startup Bootcamp Sessions" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Online Chat Support During the Summit" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Email Support" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Digital Event Guide" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Featured Company Profiles" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="On-Demand Recordings" />
                           </ListItem>
                           <Divider light />
                           <ListItem sx={{ textAlign: "center" }}>
                              <ListItemText primary="Phone Support" />
                           </ListItem>
                        </>}
                  </List>
               </div>
            </Stack>
            <Grid className="registration-pricing-web" container spacing={3} sx={{ pt: 2, pr: 6, pl: 6, pb: 6 }}>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#7956C7" }}>
                     <h2>Professional Lite</h2>
                     <h4>Now only $597</h4>
                     <h4>
                        <strike>$697</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#7956C7" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#643DB8" }}>
                     <h2>Professional VIP</h2>
                     <h4>Now only $697</h4>
                     <h4>
                        <strike>$797</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#643DB8" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary=" One Ticket" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 2 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings – Add-on $100" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
               <Grid item xs={4}>
                  <Item sx={{ backgroundColor: "#491F9A" }}>
                     <h2>Professional Platinum</h2>
                     <h4>Now only $797</h4>
                     <h4>
                        <strike>$897</strike>
                     </h4>
                     <List

                        sx={{ ...style, backgroundColor: "#491F9A" }}
                        component="nav"
                        aria-label="mailbox folders"
                     >
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText>
                              <b> Two Tickets</b>
                           </ListItemText>
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="One-on-One Networking" />
                        </ListItem>
                        <Divider />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Networking App" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Early Access to Networking App – 10 days before Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Powerful Matchmaking Engine" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="View Attendee Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Venture Panels" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to all Workshops" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Company Presentations" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Access to Startup Bootcamp Sessions" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Online Chat Support During the Summit" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Email Support" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Digital Event Guide" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Featured Company Profiles" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="On-Demand Recordings" />
                        </ListItem>
                        <Divider light />
                        <ListItem sx={{ textAlign: "center" }}>
                           <ListItemText primary="Phone Support" />
                        </ListItem>
                     </List>
                  </Item>
               </Grid>
            </Grid>
         </TabPanel>
      </Box>
   );
};
