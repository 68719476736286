import "./App.css";
import { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import {
//    Home as West22Home,
//    MatchmakingTest,
//    Sponsors,
//    FAQ,
//    Press,
//    Agenda,
//    Register,
//    TopInnovator,
// } from "./events/venture-summit-west-21/pages/index";

// import {
//    MatchmakingTest as MatchmakingSummer22,
//    Sponsors as SponsorsSummer22,
//    FAQ as FAQSummer22,
//    Press as PressSummer22,
//    Agenda as AgendaSummer22,
//    Register as RegisterSummer22,
//    TopInnovator as TopInnovatorSummer22,
//    HomeTest as Summer22Home,
//    Venue,
// } from "./events/summer-22/pages/index";

// import {
//    MatchmakingTest as Matchmaking22,
//    Sponsors as Sponsors22,
//    FAQ as FAQ22,
//    Press as Press22,
//    Agenda as Agenda22,
//    Register as Register22,
//    TopInnovator as TopInnovator22,
//    HomeTest as Home22,
//    Venue as Venue22,
//    Presenters as Presenters22,
//    Volunteer as Volunteer22
// } from "./events/fvs-22/pages/index";


// import {
//    Home as HomeEtvs23,
//    Startups as StartupsEtvs23,
//    Partners as PartnersEtvs23,
//    FAQ as FAQEtvs23,
//    Press as PressEtvs23,
//    AgendaTemp as AgendaEtvs23,
//    Agenda as AgendaEtvs23,
//    Register as RegisterEtvs23,
//    Venue as VenueEtvs23,
//    Volunteer as VolunteerEtvs23,
//    Speakers as SpeakersEtvs23,
//    Matchmaking as MatchmakingEtvs23,
//    Presenters as PresentersEtvs23,
//    TopInnovator as TopInnovatorEtvs23,
// } from "./events/etvs-23/pages/index";

import {
   Home,
   Testimonials,
   Contact,
   About,
   Summits,
   VentureNurturing,
   Nominations,
   FAQNew,
   InvitedSpeakerSubmission,
   AdvocateProgram,
   InvitedJudgeOnStageSubmission,
   ConfirmSpeakerSubmission,
   InvitedJudgeSubmission,
   TYSubmission,
   Subscribe,
   Unsubscribe,
   Page404,
   FormSuccess,
   SponsorFileUpload,
   Dashboard
} from "./pages/index";

import "bootstrap/dist/css/bootstrap.min.css";
import ScrollToTop from "./utils/ScrollToTop";



function App() {
   useEffect(() => {
      ReactGA.initialize('UA-49742584-6');
      ReactGA.pageview('Init page view');
   }, [])
   return (
      // GENERAL ROUTES
      <BrowserRouter>
         <ScrollToTop />
         <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/vc-dashboard" element={<Dashboard />} />
            {/* change navbar */}
            <Route
               path="/invited-judge-submission"
               element={<InvitedJudgeSubmission />}
            />
            <Route
               path="/invited-speaker-submission"
               element={<InvitedSpeakerSubmission />}
            />
            <Route
               path="/invited-judge-on-stage-submission"
               element={<InvitedJudgeOnStageSubmission />}
            />
            <Route
               path="/confirmed-speaker-submission/"
               element={<ConfirmSpeakerSubmission />}
            />
            <Route
               path="/advocate-program/"
               element={<AdvocateProgram />}
            />
            <Route path="/venture-nurturing" element={<VentureNurturing />} />
            <Route path="/nominations" element={<Nominations />} />
            <Route path="/thank-you-for-your-submission" element={<TYSubmission />} />
            <Route path="/form-success" element={<FormSuccess />} />
            <Route path="/sponsor-file-upload" element={<SponsorFileUpload />} />
            {/*  */}


            <Route path="/subscribe" element={<Subscribe />} />
            <Route path="/unsubscribe" element={<Unsubscribe />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/summits" element={<Summits />} />
            <Route path="/faq" element={<FAQNew />} />
            <Route path="/testimonials" element={<Testimonials />} />



            {/* VENTURE SUMMIT WEST 22 ROUTES */}
            {/* <Route path="/events/west22" element={<West22Home />} /> */}
            {/* <Route path="/events/west22/registration" element={<Register />} /> */}
            {/* <Route path="/events/west22/matchmaking" element={<MatchmakingTest />} /> */}
            {/* <Route path="/events/west22/sponsors" element={<Sponsors />} /> */}
            {/* <Route path="/events/west22/faq" element={<FAQ />} /> */}
            {/* <Route path="/events/west22/press-passes" element={<Press />} /> */}
            {/* <Route path="/events/west22/agenda" element={<Agenda />} /> */}
            {/* <Route path="/events/west22/top-innovators" element={<TopInnovator />} /> */}

            {/* VENTURE SUMMIT SUMMER 22 ROUTES */}
            {/* <Route path="/events/summer22/registration" element={<RegisterSummer22 />} /> */}
            {/* <Route path="/events/summer22" element={<Summer22Home />} /> */}
            {/* <Route path="/events/summer22/matchmaking" element={<MatchmakingSummer22 />} /> */}
            {/* <Route path="/events/summer22/sponsors" element={<SponsorsSummer22 />} /> */}
            {/* <Route path="/events/summer22/faq" element={<FAQSummer22 />} /> */}
            {/* <Route path="/events/summer22/press-passes" element={<PressSummer22 />} /> */}
            {/* <Route path="/events/summer22/agenda" element={<AgendaSummer22 />} /> */}
            {/* <Route path="/events/summer22/top-innovators" element={<TopInnovatorSummer22 />} /> */}
            {/* <Route path="/events/summer22/venue" element={<Venue />} /> */}

            {/* Florida 22 SUMMIT ROUTES */}
            {/* <Route path="/events/fvs22/registration" element={<Register22 />} /> */}
            {/* <Route path="/events/fvs22" element={<Home22 />} /> */}
            {/* <Route path="/events/fvs22/matchmaking" element={<Matchmaking22 />} /> */}
            {/* <Route path="/events/fvs22/sponsors" element={<Sponsors22 />} /> */}
            {/* <Route path="/events/fvs22/faq" element={<FAQ22 />} /> */}
            {/* <Route path="/events/fvs22/press-passes" element={<Press22 />} /> */}
            {/* <Route path="/events/fvs22/agenda" element={<Agenda22 />} /> */}
            {/* <Route path="/events/fvs22/top-innovators" element={<TopInnovator22 />} /> */}
            {/* <Route path="/events/fvs22/venue" element={<Venue22 />} /> */}
            {/* <Route path="/events/fvs22/presenters" element={<Presenters22 />} /> */}
            {/* <Route path="/events/fvs22/volunteer" element={<Volunteer22 />} /> */}
            {/* <Route path="*" element={<Page404 />} /> */}

            {/* EMERGING TECH VENTURES Summit 23 ROUTES */}
            {/* <Route path="/events/etvs23" element={<HomeEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/partners" element={<PartnersEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/speakers" element={<SpeakersEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/startups" element={<StartupsEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/agenda/*" element={<AgendaEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/agenda" element={<AgendaEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/venue" element={<VenueEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/press" element={<PressEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/faq" element={<FAQEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/volunteer" element={<VolunteerEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/registration" element={<RegisterEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/startups" element={<TopInnovatorEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/matchmaking" element={<MatchmakingEtvs23 />} /> */}
            {/* <Route path="/events/etvs23/presenters" element={<PresentersEtvs23 />} /> */}
            <Route path="*" element={<Page404 />} />
         </Routes>
      </BrowserRouter>
   );
}

export default App;
