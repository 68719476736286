import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';

import { NewButton } from '../NewButton';
import { tracking } from '../../../../utils/tracking';
import styles from './VirtualRegistrationPricing.module.css'

const Item = styled(Paper)(({ theme }) => ({
   ...theme.typography.body2,
   padding: theme.spacing(1),
   textAlign: 'center',
   color: 'white',
   fontFamily: '',
   paddingTop: '7.5vh',
   position: 'relative',
   margin: 'auto'
}));

function TabPanel(props) {
   const { children, value, index, ...other } = props;
   return (
      <div
         role='tabpanel'
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {
            value === index &&
            <Box sx={{ p: 3, width: 'fit-content', margin: 'auto' }}>
               <Typography>{children}</Typography>
            </Box>
         }
      </div>
   );
}

TabPanel.propTypes = {
   children: PropTypes.node,
   index: PropTypes.number.isRequired,
   value: PropTypes.number.isRequired,
};

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

const benefits = [
   [
      'Access to Panels',
      'Access to Workshops',
      'Access to Startup Pitches',
      'Discounted Hotel Rates \n *While they last',
      'Opening Reception Ticket \n *Drink tix included for Gold/Platinum',
   ],
   [
      'Access to Matchmaking',
      'Access to Event App',
      'Digital Event Guide',
      'Email Support',
   ],
   [
      'Early Access to Networking \n *7 days before Gold \n ticket holders',
      'VIP Seating | Main Stage',
      'Discount for Additional Tickets',
      'Phone Support'
   ],
   [
      'Company Description \n in Event Guide'
   ]
]

const registerCards = [
   {
      benefitsCardColor: 'linear-gradient(180deg, #763E5D 0%, #CA5A83 100%)',
      title: 'Startups',
      types: [
         { name: 'Silver', price: 797, color: 'linear-gradient(180deg, #482944 0%, #D65F89 100%)', benefits: [...benefits[0]] },
         { name: 'Gold', price: 897, color: 'linear-gradient(180deg, #3D0F36 0%, #CF1B5B 100%)', benefits: [...benefits[0], ...benefits[1]] },
         { name: 'Platinum Package', price: 1697, color: 'linear-gradient(180deg, #482944 0%, #D65F89 100%)', benefits: [...benefits[0], ...benefits[1], ...benefits[2], ...benefits[3]] },
      ]
   },

   {
      benefitsCardColor: 'linear-gradient(180deg, #207036 0%, #417B51 100%)',
      title: 'Investors',
      types: [
         { name: 'Silver', price: 897, color: 'linear-gradient(180deg, #106B2A 0%, #457C55 100%)', benefits: [...benefits[0]] },
         { name: 'Gold', price: 997, color: 'linear-gradient(180deg, #014408 0%, #0D782B 100%)', benefits: [...benefits[0], ...benefits[1]] },
         { name: 'Platinum Package', price: 1797, color: 'linear-gradient(180deg, #106B2A 0%, #457C55 100%)', benefits: [...benefits[0], ...benefits[1], ...benefits[2]] },
      ]
   },
   {
      benefitsCardColor: 'linear-gradient(180deg, #483972 0%, #775ACA 100%)',
      title: 'General Attendee',
      types: [
         { name: 'Silver', price: 997, color: 'linear-gradient(180deg, #312948 0%, #7E5FD6 100%)', benefits: [...benefits[0]] },
         { name: 'Gold', price: 1097, color: 'linear-gradient(180deg, #200C57 0%, #4F1DDD 100%)', benefits: [...benefits[0], ...benefits[1]] },
         { name: 'Platinum Package', price: 1897, color: 'linear-gradient(180deg, #312948 0%, #7E5FD6 100%)', benefits: [...benefits[0], ...benefits[1], ...benefits[2]] }
      ]
   },
]
//there are 15 forms with consecutive numbers that change every event this is the id of the first, ticketsIsShowMore lite
const WIDGET_ID_BASE = 235354;

const tabs = [
   { name: 'STARTUPS', color: 'linear-gradient(180deg, #482944 0%, #D65F89 100%)' },
   { name: 'INVESTORS', color: 'linear-gradient(180deg, #106B2A 0%, #457C55 100%)' },
   { name: 'GENERAL ATTENDEES', color: 'linear-gradient(180deg, #312948 0%, #7E5FD6 100%)' },
]

export const VirtualRegistrationPricing = ({ discount }) => {

   const [value, setValue] = useState(0);
   const [open, setOpen] = useState(false);
   const [ticketsIsShowMore, setTicketsIsShowMore] = useState(Array(15).fill(false));
   const [widgetId, setWidgetId] = useState(WIDGET_ID_BASE);
   const [showForm, setShowForm] = useState('none');
   const [showLoader, setShowLoader] = useState(false);
   const [width, setWidth] = useState(window.innerWidth)


   const handleChange = (event, newValue) => {
      setValue(newValue);
   };

   const handleClickOpen = () => {
      setOpen(true);
      setShowLoader(true);
      setTimeout(() => {
         setShowLoader(false);
         setShowForm('block')
      }, 2000)
   };

   const handleClose = () => {
      setOpen(false);
      setShowLoader(false);
      setShowForm('none')
   };


   useEffect(() => {
      var bz = document.createElement('script');
      bz.type = 'text/javascript';
      bz.async = true;
      bz.src = 'https://organizer.bizzabo.com/widgets/tickets/tickets.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(bz, s);

      function updateSize() {
         setWidth(window.innerWidth);
      }
      window.addEventListener('resize', updateSize);
      updateSize();
      return () => window.removeEventListener('resize', updateSize);
   });

   return (
      <Box sx={{ width: '100%', color: 'white !important' }}>
         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Tabs
               onChange={handleChange}
               aria-label='basic tabs example'
               scrollButtons='auto'
               sx={{
                  marginBottom: '2em',
               }}
               orientation={width < 1050 ? 'vertical' : 'horizontal'}
               style={{ flexDirection: width < 1050 ? 'column' : 'row' }}
            >
               {
                  tabs.map((tab, index) =>
                     <Tab
                        key={tab.name}
                        sx={{
                           height: '70px',
                           whiteSpace: 'nowrap',
                           maxWidth: '350px',
                           minWidth: '250px',
                           fontWeight: '900',
                           fontSize: width < 1100 ? '.9rem !important' : '1.5rem !important'
                        }}
                        icon={
                           value === index
                              ? <NewButton name={tab.name} color='white' bgColor={tab.color} />
                              : <NewButton name={tab.name} color='#CF1B5B' bgColor='white' />
                        }
                        iconPosition={'start'}
                        {...a11yProps(index)}
                        onClick={() => tracking(300 + index)}
                     />
                  )}
            </Tabs>
         </Box>

         {
            registerCards.map((card, cardIdx) =>
               <TabPanel key={card.title} value={value} index={cardIdx}>
                  {/* MOBILE */}
                  <div
                     className={styles.mobile}
                  >
                     {
                        registerCards[cardIdx].types.map((type, typeIdx) =>
                           <Item
                              sx={{
                                 minWidth: '250px',
                                 padding: '0',
                                 background: type.color,
                                 borderRadius: '0',
                                 paddingBottom: '1em'
                              }}
                           >
                              <div className={`${styles.circle} ${styles.upleft}`}></div>
                              <div className={`${styles.circle} ${styles.upright}`}></div>

                              <section className={styles.cardHeader}>
                                 <header>
                                    <small>Ticket Type</small>
                                    <h4>{type.name}</h4>
                                 </header>
                                 <h1><span>$</span>{type.price - discount}</h1>
                                 <h4><span>$</span>{type.price}</h4>
                                 {
                                    typeIdx === 2
                                       ? <p>Includes 3 Tickets</p>
                                       : <p style={{ color: 'transparent' }}>a</p>
                                 }
                                 <div onClick={(e) => {
                                    setWidgetId(WIDGET_ID_BASE + (cardIdx * 3 + typeIdx));
                                    handleClickOpen();
                                    tracking(304 + (cardIdx * 3 + typeIdx));
                                 }}>
                                    <NewButton name='book now' color='white' bgColor='#CF1B5B' width='150px' height='35px' />
                                 </div>

                                 <div className={styles.cardsSeparaiter}>
                                    <div className={`${styles.circle} ${styles.middleleft}`}></div>
                                    <div className={styles.recs}>
                                       <div className={styles.rec}></div>
                                       <div className={styles.rec}></div>
                                       <div className={styles.rec}></div>
                                       <div className={styles.rec}></div>
                                    </div>
                                    <div className={`${styles.circle} ${styles.middleright}`}></div>
                                 </div>
                              </section>
                              <div
                                 className={styles.showBenefits}
                                 onClick={() => {
                                    const newticketsIsShowMore = [...ticketsIsShowMore];
                                    newticketsIsShowMore[cardIdx * 3 + typeIdx] = !newticketsIsShowMore[cardIdx * 3 + typeIdx];
                                    setTicketsIsShowMore(newticketsIsShowMore);
                                 }}
                              >
                                 Show Benefits
                                 <ArrowDropDownIcon style={{ transform: ticketsIsShowMore[cardIdx * 3 + typeIdx] && 'rotate(180deg)' }} />
                              </div>
                              {
                                 ticketsIsShowMore[cardIdx * 3 + typeIdx] === true &&
                                 <List
                                    sx={{ backgroundColor: 'inherit', paddingBottom: '6.5em' }}
                                    component='nav'
                                    aria-label='mailbox folders'
                                 >
                                    {type.benefits.map((benefit, i) =>
                                       <ListItem
                                          key={Math.random() + i}
                                          sx={{
                                             minHeight: '55px',
                                             padding: '0 8px',
                                             bgcolor: i % 2 === 0 ? '#ffffff30' : '#ffffff15',
                                             textAlign: 'center',
                                          }}
                                       >
                                          <ListItemText sx={{ whiteSpace: 'pre-wrap' }} primary={benefit} />
                                       </ListItem>
                                    )}
                                    <div className={`${styles.circle} ${styles.bottomleft}`}></div>
                                    <div className={`${styles.circle} ${styles.bottomright}`}></div>
                                 </List>
                              }
                           </Item>
                        )}
                  </div>
                  {/* DESKTOP */}
                  <div className={styles.web}>
                     <div
                        className={styles.benefitsList}
                        style={{
                           background: card.benefitsCardColor,
                           marginBottom: '91px',

                        }} >
                        <List
                           sx={{ backgroundColor: 'inherit', padding: '0' }}
                           component='nav'
                           aria-label='mailbox folders'
                        >
                           {card.types[card.types.length - 1].benefits.map((benefit, i) =>
                              benefit.includes('*')
                                 ?
                                 <ListItem
                                    key={Math.random() + i}
                                    sx={{
                                       display: 'flex',
                                       flexDirection: 'column',
                                       alignItems: 'flex-start',
                                       height: '58px',
                                       padding: '0 8px',
                                       bgcolor: i % 2 === 0 ? '#ffffff30' : '#ffffff15',
                                       textAlign: 'center',
                                       paddingLeft: '2em'
                                    }}
                                 >
                                    <ListItemText sx={{ height: '50px', marginTop: '1em' }} primary={benefit.split('*')[0]} />
                                    <small >*{benefit.split('*')[1]}</small>
                                 </ListItem>
                                 :
                                 <ListItem
                                    key={Math.random() + i}
                                    sx={{
                                       height: '58px',
                                       padding: '0 8px',
                                       bgcolor: i % 2 === 0 ? '#ffffff30' : '#ffffff15',
                                       paddingLeft: '2em'
                                    }}
                                 >
                                    <ListItemText primary={benefit} />
                                 </ListItem>
                           )}
                        </List>
                     </div>

                     {registerCards[cardIdx].types.map((type, typeIdx) =>
                        <div>
                           <Item
                              sx={{
                                 minWidth: '250px',
                                 padding: '0',
                                 background: type.color,
                                 borderRadius: '0',
                              }}
                           >
                              <div className={`${styles.circle} ${styles.upleft}`}></div>
                              <div className={`${styles.circle} ${styles.upright}`}></div>
                              <div className={`${styles.circle} ${styles.bottomleft}`}></div>
                              <div className={`${styles.circle} ${styles.bottomright}`}></div>
                              <section className={styles.cardHeader}>
                                 <header>
                                    <small>Ticket Type</small>
                                    <h4>{type.name}</h4>
                                 </header>
                                 <h1><span>$</span>{type.price - discount}</h1>
                                 <h4><span>$</span>{type.price}</h4>
                                 {
                                    typeIdx === 2
                                       ? <p >Includes 3 Tickets</p>
                                       : <p style={{ color: 'transparent' }}>a</p>
                                 }
                                 <div onClick={e => {
                                    setWidgetId(WIDGET_ID_BASE + (cardIdx * 3 + typeIdx));
                                    handleClickOpen();
                                    tracking(304 + (cardIdx * 3 + typeIdx));
                                 }}>
                                    <NewButton name='book now' color='white' bgColor='#CF1B5B' width='150px' height='35px' />
                                 </div>
                                 <section className={styles.cardsSeparaiter}>
                                    <div className={`${styles.circle} ${styles.middleleft}`} />
                                    <div className={styles.recs}>
                                       <div className={styles.rec} />
                                       <div className={styles.rec} />
                                       <div className={styles.rec} />
                                       <div className={styles.rec} />
                                    </div>
                                    <div className={`${styles.circle} ${styles.middleright}`} />
                                 </section>
                              </section>
                              <List
                                 sx={{ backgroundColor: 'inherit', paddingBottom: '6.5em' }}
                                 component='nav'
                                 aria-label='mailbox folders'
                              >
                                 {card.types[card.types.length - 1].benefits.map((benefit, benefitIdx) =>
                                    <ListItem
                                       key={benefitIdx}
                                       sx={{
                                          padding: '0 8px',
                                          bgcolor: benefitIdx % 2 === 0 ? '#ffffff30' : '#ffffff15',
                                          textAlign: 'center',
                                       }}>
                                       <ListItemText
                                          primary={
                                             (type.benefits.length <= benefitIdx)
                                                ? <div className={styles.clearIcon}> <ClearIcon sx={{ fontSize: 42.5, color: '#B0B0B0' }} /></div>
                                                : <div className={styles.checkIcon}> <CheckIcon sx={{ fontSize: 50 }} /></div>
                                          }
                                       />
                                    </ListItem>
                                 )}
                              </List>
                           </Item>
                        </div>
                     )
                     }
                  </div>
                  <br />
               </TabPanel>)
         }

         <Dialog
            disableScrollLock
            open={open}
            onClose={handleClose}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
            maxWidth={'lg'}
         >
            <DialogContent sx={{ minHeight: '65vh !important', maxHeight: '85vh !important' }}>
               {showLoader && <div id='etxLoader' style={{ left: width < 700 ? '40%' : '45%', top: '35%' }} />}
               <div
                  id='biza-prc-div'
                  className='bizzabo-tickets-widget'
                  style={{ display: showForm }}
                  data-event-id='419957'
                  data-params={`isInMinisite=true&widgetId=${widgetId}`}
               />
            </DialogContent>
         </Dialog>
      </Box >
   );
};
