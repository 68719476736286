import { NavBar, Footer } from "../components/index";
import { getData } from "../api/apiCalls";
import { useEffect, useState } from "react";

export const Summits = () => {
   document.title = "youngStartup Ventures | Summits";
   const [data, setData] = useState([]);
   const [upcomingSummits, setUpcomingSummits] = useState([]);
   useEffect(() => {
      getData(`https://youngstartup.io/api/cwebsite/get_events`).then((data) => {
         setData(data.past_events);
         setUpcomingSummits(data.upcoming_events);
      });
   }, []);
   const summitList = (
      <div style={{ padding: "5rem" }}>
         {data.map((summit, i) => (
            <a
               key={i}
               href={summit.page_url}
               style={{ textDecoration: "none", color: "black !important" }}
            >
               <div
                  className="summit-card"
                  style={{
                     maxWidth: "800px",
                     backgroundColor: "white",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-around",
                     padding: "1rem",
                     borderRadius: "10px",
                  }}
               >
                  <img alt='summit' src={`/assets/event-thumbnails${summit.image}`} style={{ maxWidth: "150px" }} />
                  <div style={{ width: '75%' }}>
                     <span
                        className="summit-title"
                        style={{
                           fontSize: "2rem",
                           fontWeight: "900",
                           color: "black !important",
                           marginLeft: "2vw",
                        }}
                     >
                        {summit.title}
                     </span>
                     <div>{summit.dates_str}</div>
                  </div>
               </div>
            </a>
         ))}
      </div>
   );

   const upcomingSummitList = (
      <div style={{ padding: "5rem" }}>
         {upcomingSummits.map((summit, i) => (
            <a
               key={i}
               href={summit.page_url}
               style={{
                  textDecoration: "none", color: "black !important",
                  display: (window.innerWidth > 1200) && 'flex',
                  justifyContent: (window.innerWidth > 1200) && 'center'
               }}
            >
               <div
                  className="summit-card"
                  style={{
                     maxWidth: "800px",
                     backgroundColor: "white",
                     display: (window.innerWidth < 1200) && 'flex',

                     alignItems: "center",
                     justifyContent: "space-around",
                     padding: "1rem",
                     borderRadius: "10px",
                  }}
               >
                  {window.innerWidth > 1200 ?
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                        {/*  */}
                        <img alt='summit' src={`/assets/event-thumbnails${summit.image}`} style={{ maxWidth: "150px" }} />
                        <div >
                           <span
                              className="summit-title"
                              style={{
                                 fontSize: "2rem",
                                 fontWeight: "900",
                                 color: "black !important",
                                 marginLeft: "2vw",
                              }}
                           >
                              {summit.title}
                           </span>
                           <div>{summit.dates_str}</div>
                        </div>

                     </div>
                     :
                     <>
                        <img alt='summit' src={`/assets/event-thumbnails${summit.image}`} style={{ maxWidth: "150px" }} />
                        <div >
                           <span
                              className="summit-title"
                              style={{
                                 fontSize: "2rem",
                                 fontWeight: "900",
                                 color: "black !important",
                                 marginLeft: "2vw",
                              }}
                           >
                              {summit.title}
                           </span>
                           <div>{summit.dates_str}</div>
                        </div>
                     </>
                  }

                  <div className="summit-description" style={{
                     width: "85%", color: "black", display: 'inline-block', paddingTop: '2.5vh', whiteSpace: "pre-wrap"
                  }}>
                     {summit.description}
                  </div>
               </div>
            </a>
         ))}
      </div>
   );

   return (
      <div style={{ backgroundColor: "black" }}>
         <NavBar />
         <div
            style={{
               display: "flex",
               flexDirection: "column",
               textAlign: "center",
               justifyContent: "center",
               marginTop: "5vh",
            }}
         >
            <h1 style={{ color: 'white', fontWeight: '900' }}>UPCOMING SUMMITS</h1>
            <div
               className="summit-container"
               style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
               }}
            >
               {upcomingSummitList}
            </div>
            <h1 style={{ color: 'white', fontWeight: '900' }}>PAST SUMMITS</h1>
            <div
               className="summit-container"
               style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
               }}
            >
               {summitList}
            </div>
         </div>
         <Footer />
      </div>
   );
};
