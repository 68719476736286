import { NavBar, Footer } from "../components/index";
import { testimonials } from "../data/testimonials";

export const Testimonials = () => {
   document.title = "youngStartup Ventures | Testimonials";

   return (
      <div>
         <NavBar />
         <div className="container-fluid mb-5 " style={{ marginTop: "5vh" }}>
            <h1 style={{ textIndent: "20px" }}>Testimonials:</h1>
            <hr style={{ color: "#7C2387" }} />

            <div className="row  mx-auto mt-5">

               {testimonials.map((testimonial) => (
                  <div
                     className="col-12  col-xl-5 card  mx-auto  shadow p-3 mb-5 bg-white rounded"
                     style={{ border: "none", borderRadius: "12px !important" }}
                  >
                     <div className="card-body row my-auto  place-behind">
                        <div className="col-12 col-xl-4 my-auto">
                           <div
                              style={{
                                 width: "160px",
                                 margin: "auto",
                                 filter: "grayscale(80%)",
                              }}
                           >
                              <img alt='testimonial' src={testimonial.headshot} width="125px" style={{ borderRadius: "100%" }} />
                           </div>
                        </div>
                        <div
                           className="col-12 col-xl-8 my-auto p-3"
                           style={{
                              backgroundColor: "whitesmoke",
                              borderRadius: "10px",
                           }}
                        >
                           <div className=" " style={{ fontSize: "1rem" }}>
                              <div className="testimonial-top-card-div height-change">
                                 <span style={{ position: "absolute" }}>
                                    <i className="fas fa-quote-left" />
                                 </span>
                                 <span
                                    style={{
                                       textIndent: "20px",
                                       color: "#6c757d! important",
                                    }}
                                 >
                                    {testimonial.testimonial}
                                 </span>
                              </div>

                              <div className="testimonial-bottom-card-div mt-4">
                                 <span
                                    className="font-weight-bold"
                                    style={{ color: "#FF2945" }}
                                 >
                                    {testimonial.name}
                                 </span>
                                 ,
                                 <strong>
                                    {" "}
                                    <i>{testimonial.role}</i>
                                 </strong>
                                 ,
                                 <div style={{ color: "rgb(102, 102, 102)" }}>
                                    {testimonial.company}
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               ))}
            </div>
         </div>
         <Footer />
      </div>
   );
};
