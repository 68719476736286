import { SubscribeForm } from "./SubscribeForm";
import { Footer } from "./Footer";

export const FooterNew = () => {
   return (
      <>
         <div style={{ display: "flex", width: "100%" }}>
            <div
               style={{
                  flex: 1,
                  backgroundColor: "black",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
            >
               <SubscribeForm id={`subscribeForm3`} />
            </div>
            <img
               alt='group'
               src="/assets/media/Group 109.png"
               style={{
                  flex: 1,
                  display: window.innerWidth < 1000 ? "none" : "",
                  objectFit: "cover",
               }}
            />
         </div>
         <Footer />
      </>
   );
};
