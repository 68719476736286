import { NavBar, Footer } from "../components/index";
import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
export const Page404 = () => {
   document.title = "youngStartup Ventures | Page404";

   useEffect(() =>
      setTimeout(() => (window.location.href = "https://youngstartup.com"), 5000)
   );
   return (
      <div style={{ backgroundColor: "black" }}>
         <NavBar />
         <h1
            style={{
               color: "white",
               textAlign: "center",
               paddingTop: "15vh",
               fontWeight: "900",
            }}
         >
            404 Error - Page Not Found!
         </h1>
         <h4
            style={{
               color: "white",
               textAlign: "center",
               paddingTop: "5vh",
               fontWeight: "900",
            }}
         >
            Please wait while we redirect you to the homepage
         </h4>
         <div
            style={{
               display: "flex",
               justifyContent: "center",
               marginTop: "5vh",
               marginBottom: "5vh",
            }}
         >
            <CircularProgress />
         </div>
         <Footer />
      </div>
   );
};
