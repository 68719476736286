import { useState, useEffect } from 'react';
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const NavBar = () => {
   const [chevron, setChevron] = useState(0);
   const [margin1, setMargin1] = useState('0vw');
   const [links, setLinks] = useState({
      home: null,
      about: null,
      summits: null,
      contact: null,
      testimonials: null,
      faq: null,
   });

   useEffect(() => {

      if (window.location.href === 'https://youngstartup.com' || window.location.href === 'https://youngstartup.com/') {
         setLinks({ ...links, home: "0 2px 0px 0px #FF2945" })
      } else if (window.location.href === 'https://youngstartup.com/about' || window.location.href === 'https://youngstartup.com/about/') {
         setLinks({ ...links, about: "0 2px 0px 0px #FF2945" })
      } else if (window.location.href === 'https://youngstartup.com/summits' || window.location.href === 'https://youngstartup.com/summits/') {
         setLinks({ ...links, summits: "0 2px 0px 0px #FF2945" })
      } else if (window.location.href === 'https://youngstartup.com/contact' || window.location.href === 'https://youngstartup.com/contact/') {
         setLinks({ ...links, contact: "0 2px 0px 0px #FF2945" })
      } else if (window.location.href === 'https://youngstartup.com/testimonials' || window.location.href === 'https://youngstartup.com/testimonials/') {
         setLinks({ ...links, testimonials: "0 2px 0px 0px #FF2945" })
      } else if (window.location.href === 'https://youngstartup.com/faq' || window.location.href === 'https://youngstartup.com/faq/') {
         setLinks({ ...links, faq: "0 2px 0px 0px #FF2945" })
      }
   }, [])// eslint-disable-line 


   return (
      <nav
         className="navbar navbar-expand-lg navbar-dark navbarSticky"
         style={{
            backgroundColor: "black",
            fontFamily: "Roboto Condensed, sans-serif !important",
            position: "sticky",
            zIndex: "1002",
         }}
      >
         <div id="topmenubar" className="container" style={{ maxWidth: "100%" }}>
            <a className="navbar-brand" href="/" >
               <img
                  alt='ysv'
                  src="/assets/media/ysv-logo-white-new.png"
                  width="150"
                  height="auto"
                  style={{ paddingLeft: "1rem" }}
               />
            </a>
            <button
               className="navbar-toggler"
               type="button"
               data-toggle="collapse"
               data-target="#navbarNavDropdown"
               aria-controls="navbarNavDropdown"
               aria-expanded="false"
               aria-label="Toggle navigation"
            >
               <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
               <ul
                  className="navbar-nav  m-auto"
                  style={{ fontFamily: "Roboto Condensed, sans-serif !important" }}
               >
                  <li className="nav-item">
                     <a className="nav-link" href="/" style={{ boxShadow: links.home }}>
                        Home
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className="nav-link" href="/about" style={{ boxShadow: links.about }}>
                        About Us
                     </a>
                  </li>

                  <li className="nav-item">
                     <a className="nav-link" href="/summits" style={{ boxShadow: links.summits }}>
                        Summits
                     </a>
                  </li>

                  <li className="nav-item">
                     <a className="nav-link" href="/contact" style={{ boxShadow: links.contact }}>
                        Contact Us
                     </a>
                  </li>
                  <li className="nav-item">
                     <a id="sponsors-link" className="nav-link" href="/testimonials" style={{ boxShadow: links.testimonials }}>
                        Testimonials
                     </a>
                  </li>
                  <li className="nav-item">
                     <a className="nav-link" href="/faq" style={{ boxShadow: links.faq }}>
                        FAQ
                     </a>
                  </li>
               </ul>

               <div style={{ marginRight: "7.5%" }}></div>
               <div
                  className="button-container"
                  style={{ display: "flex", justifyContent: "space-between", width: '25%' }}
               >
                  <div>
                     <a
                        href="/events/nevs23"
                        onMouseOver={(e) => {
                           setChevron(1);
                           setMargin1("1.5vw");
                        }}
                        onMouseLeave={() => {
                           setChevron(0);
                           setMargin1("0vw");
                        }}
                        className="btn btn-primary mr-2 mb-2 text-nowrap dark-btn-red"
                        style={{
                           marginRight: "1rem",
                           fontWeight: "900",
                           fontSize: "16px",
                           display: 'flex'
                        }}
                     >
                        <ChevronRightIcon
                           sx={{ transition: "opacity 2s", opacity: 0 }}
                        />
                        <div>UPCOMING EVENT</div>
                        <ChevronRightIcon
                           sx={{
                              transition: ".3s all ease-in-out",
                              opacity: chevron,
                              marginLeft: margin1,
                           }}
                        />
                     </a>
                  </div>
               </div>
            </div>
         </div>
      </nav>
   );
};
