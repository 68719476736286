import { useEffect, useState } from "react";
import { getData } from "../api/apiCalls";
import { FooterNYVS23, NavBarNYVS23 } from '../components';

export const TYSubmission = () => {
   document.title = "youngStartup Ventures | TY Submission";

   const [currentEvent, setCurrentEvent] = useState({});
   useEffect(() => {
      getData(`https://youngstartup.io/api/cwebsite/get_current_event`)
         .then(res => {
            const title = res.title;
            const calender_links = JSON.parse(res.calender_links);
            setCurrentEvent({ title, calender_links });
         })
   }, [])

   return (
      <>
         <NavBarNYVS23 />
         <div style={{ backgroundColor: "black" }}>
            <div
               style={{
                  textAlign: "center",
                  color: "white",

                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "75vh",
               }}
            >
               <h1 style={{ fontWeight: "900" }}>Thank you for your submission!</h1>
               <br />
               <h4>Please add {currentEvent.title} to your Calendar:</h4>
               <br />
               <div style={{ display: 'flex', justifyContent: 'center', transform: "scale(1.5)", margin: '5% 0%', gap: '10%' }}>
                  <a rel='noreferrer' target="_blank" href={currentEvent.calender_links?.google}>
                     <img alt='google' border="0" src="/assets/media/google icon.png" style={{ width: '50px' }} />
                  </a>
                  <a rel='noreferrer' target="_blank" href={currentEvent.calender_links?.ics_file} download>
                     <img alt='apple' border="0" src="/assets/media/apple icon.png" style={{ width: '50px' }} />
                  </a>
                  <a rel='noreferrer' target="_blank" href={currentEvent.calender_links?.ics_file} download>
                     <img alt='outlook' border="0" src="/assets/media/outlook icon.png" style={{ width: '50px' }} />
                  </a>
               </div>
               <div>
                  Be on the lookout for email communication with important updates
                  from <span><a href="mailto:peter@youngstartup.com">Peter@youngstartup.com</a></span>
               </div>
               <br />
               <div>Best regards,</div>
               <div>The youngStartup Team</div>
            </div>
         </div>
         <FooterNYVS23 />
      </>
   );
};
