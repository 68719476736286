import axios from "axios";

import Paper from "@mui/material/Paper";
import { getData } from '../../api/apiCalls';
import { useLocation } from 'react-router-dom';
import { NewButton } from '../../events/etvs-23/components';
import { useState } from 'react';


export const LoginForm = ({ formData, setFormData, setSelectedTrack, setLoading, setNoId, setQueryParam, setJudgingArr,
   setQueryParamEmail, setShowJudgingForm, setJudgingTracksList, }) => {
   const [isLoading, setIsLoading] = useState(false)
   const location = useLocation()
   const { firstName, lastName, email, companyName } = formData;

   const handleSubmitLogin = async () => {
      if (!firstName || !lastName || !email || !companyName) {
         return;
      }
      setIsLoading(true)
      localStorage.vc_details = JSON.stringify(formData);
      localStorage.vc_email = email;
      setLoading(true);

      const res = await getData("https://youngstartup.io/api/vc-dash/getall?event=NYVS23&vc_email=" + email)
      setIsLoading(false)
      if (res.success) {
         window.location.href = location.pathname + '?ysv_id=' + res.data.vc_id

      } else {
         const object = res.judging_tracks_full_info.reduce((obj, item) => {
            obj[item.internal_name] = item.zoom_link
            return obj
         }, {});
         setJudgingArr(object);
         setJudgingTracksList(res.judging_tracks_list)
         axios.get(`https://youngstartup.io/api/cwebsite/get_current_event`)
            .then((res2) => {
               setQueryParamEmail(email);
               setShowJudgingForm(res2.data.judging_form_active);
               setLoading(false);
            });
         let postBody = {
            body: {
               form_id: 26,
               form_data: formData
            }
         }
         axios.post('https://youngstartup.io/api/forms/new_sub', postBody)
      }
      setFormData({});
      setLoading(false);
   }

   return (
      <div style={{ backgroundColor: "white", paddingBottom: "5%", height: "100%" }}>
         {
            isLoading &&
            <div style={{ height: '100vh', position: 'relative', background: 'white' }}>
               <div id="etxLoader" style={{ left: window.innerWidth > 900 ? '45%' : '40%', top: '42.5%', zIndex: '100000' }} />
            </div>
         }
         <header style={{ position: "relative" }}>
            <img
               alt='hero'
               src={
                  window.innerWidth < 900
                     // ? "/assets/media/Frame 195.png"
                     // : "/assets/media/Frame 177.png"
                     ? "/assets/media/vc-dash-baner.png"
                     : "/assets/media/vc-dash-baner.png"
               }
               style={{ width: "100%" }}
            />
            <div
               style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)",
                  width: "100%",
               }}
            >
               <h1 style={{ textAlign: "center", color: "white", width: "100%" }}>
                  JUDGING FORM
               </h1>
            </div>
         </header>

         <Paper
            style={{
               maxWidth: window.innerWidth > 900 && "600px",
               margin: "5vh auto 10vh auto",
               backgroundColor: "#0095C3",
               padding: "20px",
               paddingBottom: "2%",
               width: '90%',
            }}
            elevation={15}
         >
            <form
               style={{ textAlign: "center", padding: "25px", color: "white" }}
               onSubmit={(e) => e.preventDefault()}
            >

               <label htmlFor="fname" style={{ textAlign: "left" }}>First name*</label><br />
               <input type="text" id="fname" name="fname" required
                  style={{ width: window.innerWidth < 900 ? "100%" : "60%", marginBottom: "20px", borderRadius: "15px", padding: "7.5px 10px", outline: 'none', border: '1px solid black', }}
                  placeholder="First name" value={firstName}
                  onChange={(e) => { setFormData({ ...formData, firstName: e.target.value }) }}
               /><br />

               <label htmlFor="lname" style={{ textAlign: "left" }}>Last name*</label><br />
               <input type="text" id="lname" name="lname" required
                  style={{ width: window.innerWidth < 900 ? "100%" : "60%", marginBottom: "20px", borderRadius: "15px", padding: "7.5px 10px", outline: 'none', border: '1px solid black', }}
                  placeholder="Last name" value={lastName}
                  onChange={(e) => { setFormData({ ...formData, lastName: e.target.value }) }}
               /><br />

               <label htmlFor="email" style={{ textAlign: "left" }}>Email Address*</label><br />
               <input type="email" id="email" name="email" required
                  style={{ width: window.innerWidth < 900 ? "100%" : "60%", marginBottom: "20px", borderRadius: "15px", padding: "7.5px 10px", outline: 'none', border: '1px solid black', }}
                  placeholder="Email Address" value={email}
                  onChange={(e) => { setFormData({ ...formData, email: e.target.value }) }}
               /><br />

               <label htmlFor="company" style={{ textAlign: "left" }}>Company Name*</label><br />
               <input type="text" id="company" name="company" required
                  style={{ width: window.innerWidth < 900 ? "100%" : "60%", marginBottom: "20px", borderRadius: "15px", padding: "7.5px 10px", outline: 'none', border: '1px solid black', }}
                  placeholder="Company Name" value={companyName}
                  onChange={(e) => { setFormData({ ...formData, companyName: e.target.value }) }}
               />
               <button style={{ all: 'unset', display: 'block', margin: 'auto', width: '150px' }} onClick={handleSubmitLogin}>
                  <NewButton name='Login' color='#0095C3' bgColor='white' />
               </button>
            </form>
         </Paper>
      </div>
   )
}