import { Stack } from '@mui/material';
import { NavBar, FooterNew } from '../components/index';

export const Subscribe = () => {
   document.title = "youngStartup Ventures | Subscribe";

   return (
      <>
         <NavBar />
         <h1 style={{ color: 'white', fontWeight: '900', textAlign: 'center', backgroundColor: 'black', margin: "0", padding: window.innerWidth < 769 ? "1%" : '2%' }}>SUBSCRIBE</h1>
         <Stack spacing={2} sx={{ paddingRight: window.innerWidth < 769 ? "2%" : "22.5%", paddingLeft: window.innerWidth < 769 ? "2%" : "22.5%", backgroundColor: 'black', color: 'white', paddingBottom: '1%' }}><h3>1. Please enter your email in the "Corporate Email" field in the form below.</h3>
            <h3>2. Click off the "Corporate Email" field</h3>
            <h3 style={{ marginBottom: '0px' }}>3. A link with text will appear underneath the "Corporate Email" field. Click on that to recieve your opt-in email.</h3>
         </Stack>
         <FooterNew />
      </>
   )
}