import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import PhotoLibraryIcon from "@mui/icons-material/PhotoLibrary";

export const Footer = () => {
   return (
      <>
         <div
            className="footer"
            style={{
               width: "100%",
               padding: '1rem',
               display: "grid",
               backgroundColor: "white",
               gridTemplateColumns: "repeat(3, 1fr)",
               gridTemplateRows: "1fr",
               gridColumnGap: "0px",
               gridRowGap: "0px",
               alignItems: "center",
               boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"
            }}
         >
            <div style={{ display: "flex", flexDirection: "column" }}>
               <img
                  src="/assets/media/ysv-logo-black-new.png"
                  width="175px"
                  alt="ysv-logo"
               />
               <div>Where Innovation Meets Capital™</div>
               <div>All rights reserved © 1998 - 2022 youngStartup Ventures</div>
               <div style={{ display: "flex", flexDirection: "row", gap: '1rem' }}>
                  <a href="https://www.facebook.com/pages/youngStartup-Ventures/600332763334585">
                     <FacebookIcon />
                  </a>
                  <a href="https://www.linkedin.com/company/363980">
                     <LinkedInIcon />
                  </a>
                  <a href="https://twitter.com/youngstartupven">
                     <TwitterIcon />
                  </a>
                  <a href="https://www.youtube.com/user/youngStartup">
                     <YouTubeIcon />
                  </a>
                  <a href="https://www.flickr.com/photos/youngstartup/">
                     <PhotoLibraryIcon />
                  </a>
               </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", }}>
               <div style={{ color: '#0A58CA', fontSize: '1.35em', fontWeight: '600' }}>Local Links</div>
               <ul >
                  <li style={{ color: 'black !important' }}>
                     <a href="/" style={{ color: 'black !important' }}>Home</a>
                  </li>
                  <li>
                     <a href="/about">About Us</a>
                  </li>
                  <li>
                     <a href="/faq">FAQ</a>
                  </li>
                  <li>
                     <a href="/summits">Events</a>
                  </li>
                  <li>
                     <a href="/testimonials">Testimonials</a>
                  </li>
                  <li>
                     <a href="/contact">Contact Us</a>
                  </li>
                  <li>
                     <a href="/events/fvs22/top-innovators">Apply to Present</a>
                     {/* <a href="/events/summer22/top-innovators">Apply to Present</a> */}
                  </li>
               </ul>
               <div style={{ color: '#0A58CA', fontSize: '1.35em', fontWeight: '600' }}><a href="https://youngstartup.com/upcoming">Upcoming Summit</a></div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
               <div style={{ color: '#0A58CA', fontSize: '1.35em', fontWeight: '600' }}>LinkedIn Networks</div>
               <ul>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=354&src_table=web_footer&red_to=https://www.linkedin.com/groups/8140050/profile">
                        youngStartup Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=355&src_table=web_footer&red_to=https://www.linkedin.com/groups/8139331/profile">
                        CleanTech Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=356&src_table=web_footer&red_to=https://www.linkedin.com/groups/8138484/profile">
                        EdTech Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=357&src_table=web_footer&red_to=https://www.linkedin.com/groups/8138439/profile">
                        BioTech Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=358&src_table=web_footer&red_to=https://www.linkedin.com/groups/12121332/profile">
                        FinTech Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=359&src_table=web_footer&red_to=https://www.linkedin.com/groups/8139615/profile">
                        Healthcare IT Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=360&src_table=web_footer&red_to=https://www.linkedin.com/groups/8141206/profile">
                        Life Sciences Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=361&src_table=web_footer&red_to=https://www.linkedin.com/groups/8137893/profile">
                        Mobile Startup Network
                     </a>
                  </li>
                  <li>
                     <a href="https://youngstartup.io/api/red/gen?ysv_id=1&link_id=362&src_table=web_footer&red_to=https://www.linkedin.com/groups/8141194/profile">
                        Medical Device Startup Network
                     </a>
                  </li>
               </ul>
            </div>
         </div>
      </>
   );
};
