import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import { FooterNYVS23, NavBarNYVS23 } from '../components';

export const Nominations = () => {
   document.title = "youngStartup Ventures | Nominations";

   useEffect(() => {
      const script = document.createElement("script");
      const paper = document.getElementById("paper");
      const alert = document.getElementById("custom-stop");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      alert.style.display = "none";

      script.addEventListener("load", () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: "536350",
               formId: "d454d0a1-4638-4313-85ee-7988c7706ab4",
               target: "#nominationForm",
               onFormSubmitted: () => {
                  paper.style.display = "none";
                  alert.style.display = "flex";
               },
            });
         }
      });
   }, []);

   return (
      <div style={{ backgroundColor: '#0f1a28' }}>
         <NavBarNYVS23 />

         <h1
            id="form"
            style={{
               color: "white",
               backgroundColor: "#0f1a28",
               textAlign: "center",
               fontWeight: "900",
               paddingTop: "2.5vh",
               paddingBottom: "2.5vh",
               marginBottom: "0",
            }}
         >
            NOMINATIONS
         </h1>
         <h5
            style={{
               color: "white",
               backgroundColor: "#0f1a28",
               textAlign: "center",
               fontWeight: "900",
               paddingTop: "2.5vh",
               paddingBottom: "2.5vh",
            }}
         >
            Please do not nominate your own company. For information on how to apply
            to present <a href="https://youngstartup.com/upcoming/startups">click here.</a>
         </h5>

         <Paper
            elevation={15}
            id="paper"
            sx={{ margin: "2.5% auto", maxWidth: "1000px" }}
         >
            <div
               style={{ width: "100%", display: "flex", justifyContent: "center" }}
            ></div>
            <div
               id="nominationForm"
               style={{
                  paddingTop: "2.5vh",
                  paddingRight: "2.5vw",
                  paddingLeft: "2.5vw",
               }}
            />
         </Paper>
         <div
            style={{
               display: "flex",
               justifyContent: "center",
               padding: "1rem",
               alignItems: "center",
            }}
         >
            <Alert severity="success" id="custom-stop" sx={{ fontSize: '1.25rem', alignItems: 'center', paddingTop: '15vh' }}>
               Thank you for your nomination. We'll be in touch with the startup
               shortly. To nominate another startup,{" "}
               <a href="https://youngstartup.com/nominations">click here.</a>
            </Alert>
         </div>
         <FooterNYVS23 />

      </div>
   );
};
