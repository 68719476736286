import { useEffect } from 'react';
import "./SubscribeForm.css";

export const SubscribeForm = (props) => {
   useEffect(() => {
      const script = document.createElement('script');
      script.src = 'https://js.hsforms.net/forms/v2.js';
      document.body.appendChild(script);

      script.addEventListener('load', () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: '536350',
               formId: 'e0fd1bef-8d7d-4236-b4e9-b942db27e370',
               target: `#${props.id}`,
            })
         }
      });
   }, []);// eslint-disable-line

   return (
      <div style={{ backgroundColor: 'black', paddingBottom: '5vh' }} className="subscribe-form-central">
         <h1 className="text-center pb-1 pt-5 sign-up-form-text "
            style={{ color: "#FFF", textTransform: "uppercase", backgroundColor: 'black' }}>Join over
            <span style={{ color: "#FF2945" }}> 300k </span>
            Investors And Innovators</h1>
         <h4 className="text-center pb-2" style={{ color: "#8a8a8a", textTransform: "uppercase", }}>
            Sign up now to stay up-to-date with all our events, news, deals and more!
         </h4>
         <div id={props.id}
            style={{
               backgroundColor: 'black', paddingRight: '5vw', paddingLeft: '5vw', color: "white !important"
            }} />
      </div>
   )
}

