import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import { FooterNYVS23, NavBarNYVS23 } from '../components';

export const InvitedJudgeOnStageSubmission = () => {
   document.title = "youngStartup Ventures | Invited Judge Submission";

   useEffect(() => {
      const script = document.createElement("script");
      script.src = "//js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: "536350",
               formId: "3077a563-b669-489e-9b48-73536e2f6b59",
               target: "#invitedJudgeOnStageForm",
            });
         }
      });
   });
   return (
      <div style={{ backgroundColor: '#0f1a28' }}>
         <NavBarNYVS23 />
         <Paper elevation={3} sx={{ margin: "2.5% auto", maxWidth: "1000px" }}>
            <div
               id="invitedJudgeOnStageForm"
               style={{
                  paddingTop: "2.5vh",
                  paddingRight: "2.5vw",
                  paddingLeft: "2.5vw",
               }}
            />
         </Paper>
         <FooterNYVS23 />

      </div>
   );
};
