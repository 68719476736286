export const LeadingInvestors = () => {
   return (
      <div
         className="container investor-container"
      >
         <section id="logos">
            <div className="row">
               <div
                  className="col-md-12 text-center font-weight-bold"
                  style={{
                     paddinBottom: "20px",
                     fontFamily: "Roboto Condensed, sansSerif",
                  }}
               >
                  <h1 style={{ color: "#FF2945", marginTop: "5vh" }}>
                     Trusted by the world's leading investors
                  </h1>
                  {/* <!--#25A8DF--> */}
               </div>
            </div>

            <div className="row">

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Correlation-Ventures.jpeg"
                     data-src="/assets/media/Correlation-Ventures.jpeg"
                     alt="Correlation Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Norwest-Venture-Partners-1.jpeg"
                     data-src="/assets/media/Norwest-Venture-Partners-1.jpeg"
                     alt="Norwest Venture Partners"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>



               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Citi-Ventures-logo.png"
                     data-src="/assets/media/Citi-Ventures-logo.png"
                     alt="Citi Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Braemar-Energy-Ventures.webp"
                     data-src="/assets/media/Braemar-Energy-Ventures.webp"
                     alt="Braemer Energy Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Motorola-Solutions-Venture-Capital.webp"
                     data-src="/assets/media/Motorola-Solutions-Venture-Capital.webp"
                     alt="Motorola Solutions"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/HP-Tech-Ventures.webp"
                     data-src="/assets/media/HP-Tech-Ventures.webp"
                     alt="HP Tech Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Canvas-Ventures.webp"
                     data-src="/assets/media/Canvas-Ventures.webp"
                     alt="Canvas"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Omidyar-NetworkUpdated.webp"
                     data-src="/assets/media/Omidyar-NetworkUpdated.webp"
                     alt="Omidyar"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/500-StartupsUpdated.webp"
                     data-src="/assets/media/500-StartupsUpdated.webp"
                     alt="500 Startups"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Khosla-Ventures.webp"
                     data-src="/assets/media/Khosla-Ventures.webp"
                     alt="Khosla Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Garage-Technology-Ventures.webp"
                     data-src="/assets/media/Garage-Technology-Ventures.webp"
                     alt="Garage Technology Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Salesforce-VenturesUpdated.webp"
                     data-src="/assets/media/Salesforce-VenturesUpdated.webp"
                     alt="Salesforce Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/MPM-CapitalUpdated.webp"
                     data-src="/assets/media/MPM-CapitalUpdated.webp"
                     alt="MPM Capital"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Venrock.png"
                     data-src="/assets/media/Venrock.png"
                     alt="Venrock"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/GE-Ventures.webp"
                     data-src="/assets/media/GE-Ventures.webp"
                     alt="GE Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Digitalis-Ventures.webp"
                     data-src="/assets/media/Digitalis-Ventures.webp"
                     alt="Digitalis Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/3M-VenturesUpdated.webp"
                     data-src="/assets/media/3M-VenturesUpdated.webp"
                     alt="3M Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Samsung-Next.webp"
                     data-src="/assets/media/Samsung-Next.webp"
                     alt="Samsung Next"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/NEA.webp"
                     data-src="/assets/media/NEA.webp"
                     alt="NEA"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>

               <div
                  className="col-md-3 col-sm-4 col-6 box"
                  style={{
                     display: "inline-block",
                     margin: "auto",
                     filter: "opacity(60%) grayscale(100%)",
                  }}
               >
                  <img
                     className="lazy loaded"
                     src="/assets/media/Bain-Capital-Ventures.webp"
                     data-src="/assets/media/Bain-Capital-Ventures.webp"
                     alt="Bain Capital Ventures"
                     style={{
                        width: "100%",
                        padding: "10px",
                        height: "auto",
                        margin: "auto",
                     }}
                     data-was-processed="true"
                  />
               </div>
            </div>
         </section>
      </div>
   );
};
