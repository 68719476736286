import { useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Checkbox } from "@mui/material";
import { getData, postData } from '../../api/apiCalls';
import { NewButton } from '../../events/etvs-23/components';


const btnStyle = {
   color: "#BA1B50",
   backgroundColor: "white !important",
   borderRadius: "25px",
};

const RadioButton = ({ i }) => {
   return (
      <FormControlLabel
         sx={{
            margin: "0",
            '& .MuiRadio-root': {
               color: "white"
            }
         }}
         value={i}
         control={<Radio />}
         label={i}
         labelPlacement="top"
      />
   );
};

export const JudgingForm = ({ userData, queryParam, judgingArr, selectedTrack, judgingTracksList }) => {
   const [data, setData] = useState([]);
   const [currentTrack, setCurrentTrack] = useState(selectedTrack);
   const [currentCompanyArr, setCurrentCompanyArr] = useState([]);
   const [currentCompany, setCurrentCompany] = useState({});
   const [rating, setRating] = useState(null);
   const [feedback, setFeedback] = useState("");
   const [connect, setConnect] = useState(false);
   const [open, setOpen] = useState(false);
   const [disabled, setDisabled] = useState(false);
   const [selectedRound, setSelectedRound] = useState(0);
   const [isLoading, setIsLoading] = useState(false)

   const handleClickOpen = () => {
      setOpen(true);
   };

   const handleClose = () => {
      setOpen(false);
   };

   const handleChangeRating = (e) => {
      currentCompany.rating = e.target.value;
      let body = {
         body: {
            vc_id: queryParam,
            presenter_id: currentCompany?.id,
            rating: e.target.value,
            comments: feedback,
            connect_w_pres: connect
         },
      };
      postData(`https://youngstartup.io/api/vc-dash/judging_sub`, body)
      setRating(e.target.value);
   };

   const handleChangeFeedback = (e) => {
      currentCompany.comments = e.target.value;
      setFeedback(e.target.value);
   };

   const handleSubmitFeedback = (e) => {
      let body = {
         body: {
            vc_id: queryParam,
            presenter_id: currentCompany?.id,
            rating: rating,
            comments: e.target.value,
            connect_w_pres: connect
         },
      };
      postData(`https://youngstartup.io/api/vc-dash/judging_sub`, body)
   };

   const handleChangeConnect = () => {
      currentCompany.connect_w_pres = !connect;
      let body = {
         body: {
            vc_id: queryParam,
            presenter_id: currentCompany?.id,
            rating: rating,
            comments: feedback,
            connect_w_pres: !connect
         },
      };
      postData(`https://youngstartup.io/api/vc-dash/judging_sub`, body)
      setConnect(!connect);
   };

   const handleNext = () => {
      currentCompanyArr.forEach((company, i) => {
         if (company.id === currentCompany.id) {
            if (currentCompany?.id !== currentCompanyArr[currentCompanyArr.length - 1]?.id) {
               setCurrentCompany(currentCompanyArr[i + 1]);
            } else {
               setDisabled(true);
               //to enable handlePrevious and have a state change to show data
               setCurrentCompany(currentCompanyArr[0]);
            }
         }
      });

      setFeedback("");
      setRating(null);
      setConnect(false);
   };

   const handlePrevious = () => {
      if (disabled) {
         setCurrentCompany(currentCompanyArr[currentCompanyArr.length - 1]);
         setDisabled(false);
         return;
      }
      const index = currentCompanyArr.findIndex(company => company.id === currentCompany.id);
      if (index > 0) {
         setCurrentCompany(currentCompanyArr[index - 1]);
      }
   };

   const handleNextRound = () => {
      if (selectedRound < data.length) {
         setSelectedRound(selectedRound + 1);
         setDisabled(false);
         setCurrentCompanyArr(data[selectedRound + 1].data);
      }
   }


   const tokenArray = [
      "91cda4f0-825a-43e9-a03d-553a199b7cdb",
      "36939cd1-c855-4c14-a794-0bc35df3d19d",
      "680c6db3-c80c-4329-8ad2-40e82e1a4235",
      "ba3d5432-620b-4e12-a17f-f5bb4c30462d",
      "3246a25e-6dcc-4016-894e-0f80f0cf24ca",
      "787be293-94d4-4518-82ec-2f5fd2c28b06",
      "29ea6468-d947-4110-bcc3-c140d2ab7bc0",
      "f89676d7-68cd-4605-ae3d-97d6e262b129",
      "2d133488-8559-4af2-b7cf-09d3af310e28",
      "c0dbf582-485e-4fc1-93d3-5d8e749ba011",
      "52609336-b23e-4fff-ad42-edb056689cb2",
   ]

   useEffect(() => {
      setIsLoading(true)

      const random = Math.floor(Math.random() * (9 - 0));
      getData(
         `https://youngstartup.io/api/cwebsite/get_presenting_comp_track_w_sub?event=NYVS24&track=${currentTrack}&ysv_id=${queryParam}&tok=${tokenArray[random]
         }`
      ).then((data) => {
         setIsLoading(false)
         if (data.length) {
            setData(data);
            setSelectedRound(0);
            setCurrentCompanyArr(data[0].data);
         }

      })
   }, [currentTrack]);// eslint-disable-line 

   useEffect(() => {
      if (currentCompanyArr?.length) {
         setCurrentCompany(currentCompanyArr[0]);
      }
   }, [currentCompanyArr]);

   useEffect(() => {
      setRating(currentCompany?.rating ? parseInt(currentCompany?.rating) : null);
      setFeedback(currentCompany?.comments ? currentCompany?.comments : "");
      setConnect(currentCompany?.connect_w_pres ? currentCompany?.connect_w_pres : false);
   }, [currentCompany]);

   return (
      <Stack sx={{ margin: "2.5%", bgcolor: "#0095C3", p: 1 }} spacing={2}>
         {
            (!currentTrack || !currentCompanyArr.length) && <div style={{ padding: "2em", color: "white", textAlign: "center" }} >
               <h5 style={{ paddingBottom: "1em" }}>Please select your Judging Track.</h5>
               <button onClick={handleClickOpen} style={{ all: 'unset', width: '250px' }}>
                  <NewButton name='Select a track' color='#0095C3' bgColor='white' />
               </button>
               <Dialog
                  disableScrollLock
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth
                  maxWidth={"sm"}
               >
                  <DialogContent>
                     <Stack spacing={2}>

                        {judgingTracksList?.map((track) => {
                           return (
                              <div
                                 key={track.id}
                                 onClick={(e) => {
                                    setCurrentTrack(track.internal_name);
                                    setDisabled(false)
                                    handleClose();
                                 }}
                                 style={{
                                    color: "white",
                                    backgroundColor: "#0095C3",
                                    padding: "2.5%",
                                    width: window.innerWidth < 900 ? "90%" : "300px",
                                    margin: ".5vh auto",
                                    textAlign: "center",
                                    cursor: "pointer"
                                 }}
                              >
                                 {track.name}
                              </div>
                           );
                        })}
                     </Stack>
                  </DialogContent>
               </Dialog>
            </div>
         }
         {
            isLoading &&
            <div style={{ height: '100vh', position: 'relative', background: 'white' }}>
               <div id="etxLoader" style={{ left: window.innerWidth > 900 ? '42.5%' : '40%', top: '5em', zIndex: '100000' }} />
            </div>
         }
         {currentTrack && currentCompany?.company && <>
            {/* {currentTrack && userData && currentCompany?.company && <> */}
            <div className='form' style={{ padding: "15px" }} >


               <section style={{ display: 'flex', justifyContent: 'space-between', alignItems: window.innerWidth > 900 ? 'flex-start' : 'center', flexDirection: window.innerWidth > 900 ? 'row' : 'column-reverse', gap: '1em' }}>
                  <div>
                     <h5 style={{ color: "white" }}>
                        {judgingTracksList.filter(t => t.internal_name === currentTrack)[0]?.name + " Track"}
                     </h5>
                     <div style={{ color: "white" }}>Location: {judgingTracksList.filter(t => t.internal_name === currentTrack)[0]?.location || "TBA"}</div>
                  </div>
                  <button onClick={handleClickOpen} style={{ all: 'unset', width: '175px' }}>
                     <NewButton name='Select Track' color='#0095C3' bgColor='white' />
                  </button>
               </section>

               <div style={{ textAlign: "center" }}>
                  {data.map((item, i) => {
                     return (
                        <Button
                           key={i}
                           variant="contained"
                           sx={{
                              color: "#BA1B50",
                              backgroundColor: selectedRound === i ? "#CCCCCC !important" : "white !important",
                              boxShadow: selectedRound === i ? "inset -2.02103px 2.02103px 4.04207px rgba(0, 0, 0, 3.92)"
                                 : "-2.02103px 2.02103px 4.04207px rgba(0, 0, 0, 0.65)",
                              borderRadius: "25px",
                              margin: "15px auto",
                              display: "flex",
                              alignSelf: "center",
                              width: window.innerWidth < 900 ? "90%" : "60%"
                           }}
                           onClick={() => { setCurrentCompanyArr(item.data); setSelectedRound(i); setDisabled(false); }}
                        >
                           <div>
                              <div style={{ fontSize: "large" }}>{item.name}</div>
                              <div style={{ color: "black", fontSize: "smaller" }}>click here</div>
                           </div>
                           <div style={{ color: "black", paddingLeft: "1em" }}>{item.times_str}</div>
                        </Button>
                     )
                  })}
               </div>
            </div>

            {!disabled && <>
               <div style={{ width: "80%", margin: "2.5vh auto", color: "white" }}>
                  Please provide each company with feedback between 1 - 10 (10 being the
                  highest)
               </div>
               <h4 style={{ color: "white", textAlign: "center" }}>
                  {currentCompany?.company}
               </h4>
               <RadioGroup
                  value={rating}
                  row
                  sx={{ justifyContent: "flex-start", color: "white", margin: '1em auto 0 !important', flexWrap: 'wrap !important', }}
                  onChange={handleChangeRating}
               >
                  {[...Array(10).keys()].map((number, i) => {
                     return <RadioButton i={i + 1} key={i} />;
                  })}
               </RadioGroup>
               <div style={{ color: "white" }}>Please Provide Feedback For The Presenter (optional)</div>
               <textarea
                  style={{ backgroundColor: "white" }}
                  rows={8}
                  value={feedback}
                  onChange={handleChangeFeedback}
                  onBlur={handleSubmitFeedback}
               />
               <FormControlLabel
                  control={
                     <Checkbox
                        checked={connect}
                        onChange={handleChangeConnect}
                        sx={{
                           "&.MuiCheckbox-root": {
                              color: "white",
                           }
                        }} />}
                  label="Put me in touch with this presenter"
                  sx={{
                     color: "white",
                  }}
               />
            </>}

            {disabled && (selectedRound + 1 >= data.length) &&
               <h2 style={{ color: "white", textAlign: "center" }}>Thank you for your feedback!</h2>}

            {currentCompanyArr?.length > 0 &&
               <div style={{ margin: "2.5vh auto", display: "flex", gap: "5%" }}>
                  <Button
                     variant="contained"
                     sx={btnStyle}
                     disabled={(currentCompany?.id) === (currentCompanyArr[0]?.id) && true}
                     onClick={handlePrevious}
                     style={{ width: "50%" }}
                     startIcon={<img src={"/assets/media/button-arrow-left.png"} alt="" />}
                  >
                     Previous
                  </Button>
                  {!disabled && <Button
                     variant="contained"
                     sx={btnStyle}
                     disabled={disabled}
                     onClick={handleNext}
                     style={{ width: "50%" }}
                     endIcon={<img src={"/assets/media/button-arrow-right.png"} alt="" />}
                  >
                     {currentCompany?.id === currentCompanyArr[currentCompanyArr.length - 1]?.id ? "Save" : "Next"}
                  </Button>}
                  {disabled &&
                     <Button
                        variant="contained"
                        sx={btnStyle}
                        onClick={handleNextRound}
                        style={{ width: "50%" }}
                        disabled={selectedRound + 1 >= data.length}
                     >
                        Next Round
                     </Button>}
               </div>}
            <Dialog
               disableScrollLock
               open={open}
               onClose={handleClose}
               aria-labelledby="alert-dialog-title"
               aria-describedby="alert-dialog-description"
               fullWidth
               maxWidth={"sm"}
            >
               <DialogContent>
                  <Stack spacing={2}>
                     {judgingTracksList?.map((track, i) => {
                        return (
                           <div
                              key={i}
                              onClick={(e) => {
                                 setCurrentTrack(track.internal_name);
                                 setDisabled(false)
                                 handleClose();
                              }}
                              style={{
                                 color: "white",
                                 backgroundColor: "#0095C3",
                                 padding: "2.5%",
                                 width: window.innerWidth < 900 ? "90%" : "300px",
                                 margin: ".5vh auto",
                                 textAlign: "center",
                                 cursor: "pointer"
                              }}
                           >
                              {track.name}
                           </div>
                        );
                     })}
                  </Stack>
               </DialogContent>
            </Dialog>
         </>}
      </Stack>
   );
};
