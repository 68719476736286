import { useEffect } from "react";
import { NavBar, Footer } from "../components/index";
import Paper from "@mui/material/Paper";
export const Contact = () => {
   document.title = "youngStartup Ventures | Contact";

   useEffect(() => {
      const script = document.createElement("script");
      script.src = "https://js.hsforms.net/forms/v2.js";
      document.body.appendChild(script);

      script.addEventListener("load", () => {
         if (window.hbspt) {
            window.hbspt.forms.create({
               portalId: "536350",
               formId: "bdbe125c-3475-4d1b-9c0b-2217a3d9a5a9",
               target: "#contactForm",
            });
         }
      });
   });

   return (
      <>
         <NavBar />
         <h1
            style={{
               textAlign: "center",
               color: "white",
               backgroundColor: "black",
               paddingTop: "2.5vh",
               paddingBottom: "2.5vh",
               fontSize: "3.5rem",
               fontWeight: "900",
            }}
         >
            CONTACT US
         </h1>
         <Paper elevation={3} sx={{ m: 5, p: 5 }}>
            <div
               id="contactForm"
               style={{ paddingRight: "5vw", paddingLeft: "5vw", paddingTop: "5vh" }}
            ></div>
         </Paper>
         <Footer />
      </>
   );
};
