import * as React from "react";
import { useState } from "react";
import { NavBar } from "../components/index";
import Box from "@mui/material/Box";
import { Footer } from "../components/index";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export const FAQNew = () => {
   document.title = "youngStartup Ventures | FAQ";

   const [questions, setQuestions] = useState({
      one: false,
      two: false,
      three: false,
      four: false,
      five: false,
      six: false,
      seven: false,
      eight: false,
      nine: false,
      ten: false,
      eleven: false,
      twelve: false,
      thirteen: false,
      fourteen: false,
      fifteen: false,
      sixteen: false,
      seventeen: false,
      eighteen: false,
      nineteen: false,
      twenty: false,
      twentyOne: false,
      twentyTwo: false,
      twentyThree: false,
      twentyFour: false,
      twentyFive: false,
      twentySix: false,
      twentySeven: false,
      twentyEight: false,
   });


   return (
      <div>
         <NavBar />
         <Box sx={{ borderBottom: 1, borderColor: 'divider', textAlign: 'left', justifyContent: 'center', p: 5, background: 'linear-gradient(to right, #6D0A22, #D41125)' }}>
            <h1 style={{ textAlign: 'center', color: 'white', fontWeight: '900', marginBottom: '5vh' }}>FAQ</h1>
            <h1 style={{ textAlign: "center", color: 'white', fontFamily: "Roboto Condensed, sans-serif", fontWeight: '900', marginTop: '2.5vh' }}>General Info</h1>
            <div>
               <div className="question" onClick={() => { questions.one === false ? setQuestions({ ...questions, one: true }) : setQuestions({ ...questions, one: false }) }}>What activities happen at the summit? {questions.one === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.one && <div className="answer">Our programs feature over 100 VCs as speakers/judges (many others in attendance); presentations by more than 100 Top Innovators; and high-level networking opportunities. An agenda is available <a href="/events/summer22/agenda">here</a>.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.two === false ? setQuestions({ ...questions, two: true }) : setQuestions({ ...questions, two: false }) }}>How many people attend? {questions.two === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.two && <div className="answer">Our exclusive venture summits traditionally bring together over 1000 VCs, Corporate VCs, Angel Investors, investment bankers, and CEOs of early-stage and emerging growth companies as well as tech transfers and service providers.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.three === false ? setQuestions({ ...questions, three: true }) : setQuestions({ ...questions, three: false }) }}>Is there a networking app that allows for messaging participants? {questions.three === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.three && <div className="answer">Yes. It is available for registered attendees before the summit.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.four === false ? setQuestions({ ...questions, four: true }) : setQuestions({ ...questions, four: false }) }}>Do you share the email addresses of the investors or other participants? {questions.four === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.four && <div className="answer">No, however, one can message participants using the networking app.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <h1 style={{ textAlign: "center", color: 'white', fontFamily: "Roboto Condensed, sans-serif", fontWeight: '900', marginTop: '2.5vh' }}>Startups</h1>
            <div>
               <div className="question" onClick={() => { questions.five === false ? setQuestions({ ...questions, five: true }) : setQuestions({ ...questions, five: false }) }}>What types of startups are best suited to the summit? {questions.five === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.five && <div className="answer">The main focus is on companies within the Tech, Life Sciences/Healthcare, CleanTech, and FinTech sectors, who are seeking series A-C for amounts of approx. 2-30M. We also feature an industry-agnostic seed-stage track.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.six === false ? setQuestions({ ...questions, six: true }) : setQuestions({ ...questions, six: false }) }}>How can a startup participate? {questions.six === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.six && <div className="answer">Companies can either attend to make connections and build up their investor network, use the messaging app, as well as participate in the VC panel sessions. Companies looking for added visibility can apply to present.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.seven === false ? setQuestions({ ...questions, seven: true }) : setQuestions({ ...questions, seven: false }) }}>How do I register to attend and what is included? {questions.seven === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.seven && <div className="answer">Various attendee packages and benefits are available <a href="/events/summer22/registration">here</a>.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.eight === false ? setQuestions({ ...questions, eight: true }) : setQuestions({ ...questions, eight: false }) }}>What presentation options exist for startups? {questions.eight === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.eight && <div className="answer">Companies seeking series A-C for amounts of 2-30 million approx. can apply to present to the Top Innovators programs. Seed stage companies can apply to the Seed Pitchfest program.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.nine === false ? setQuestions({ ...questions, nine: true }) : setQuestions({ ...questions, nine: false }) }}>What is the goal of the presentations? {questions.nine === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.nine && <div className="answer">The presentation programs are designed for emerging growth companies looking to raise visibility amongst an audience of active investors. The presentations are geared for capital raises, not topical discussions.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.ten === false ? setQuestions({ ...questions, ten: true }) : setQuestions({ ...questions, ten: false }) }}>How many startups will be presenting? {questions.ten === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.ten && <div className="answer">There will be over 100 startups presenting as Top Innovators, with about 30 startups per industry track. Seed Pitchfest will feature around 40-50 startups.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.eleven === false ? setQuestions({ ...questions, eleven: true }) : setQuestions({ ...questions, eleven: false }) }}>What are the selection criteria for presenters? {questions.eleven === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.eleven && <div className="answer">Criteria are based on industry, stage, business model, size of the target audience, milestones achieved to date, whether the company would be of interest to the investors at this specific program, and other factors.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.twelve === false ? setQuestions({ ...questions, twelve: true }) : setQuestions({ ...questions, twelve: false }) }}>How does one apply? {questions.twelve === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twelve && <div className="answer"><span>For the Top Innovators program <a href="https://youngstartup.com/upcoming/top-innovators">click here</a></span>
               </div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.thirteen === false ? setQuestions({ ...questions, thirteen: true }) : setQuestions({ ...questions, thirteen: false }) }}>How long does the application and screening process take? {questions.thirteen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.thirteen && <div className="answer">
                  <div>The online form only takes about 30 minutes to complete.</div>
                  <br />
                  <div>The Review Committee processes the applications on a rolling basis and will update the applicants as soon as they have an answer for them. This typically is done with 10 – 14 days. Allow for additional time, if one has applied well in advance of the summit.</div></div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.fourteen === false ? setQuestions({ ...questions, fourteen: true }) : setQuestions({ ...questions, fourteen: false }) }}>What is the presentation format? {questions.fourteen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.fourteen && <div className="answer">For the Top Innovators program, the presentation slots are seven minutes in length with an additional 3 for Q&A in front of a live audience of Investors actively investing in your sector.

                  For companies presenting on the seed stage track, the presentations are three minutes in front of industry-agnostic investors.</div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.fifteen === false ? setQuestions({ ...questions, fifteen: true }) : setQuestions({ ...questions, fifteen: false }) }}>Does it include a booth? {questions.fifteen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.fifteen && <div className="answer">A booth can be setup as part of a low-cost sponsorship package. For more info email: <a href="mailto:sponsors@youngstartup.co">sponsors@youngstartup.co</a></div>}
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <div>
               <div className="question" onClick={() => { questions.sixteen === false ? setQuestions({ ...questions, sixteen: true }) : setQuestions({ ...questions, sixteen: false }) }}>Do you charge for the service you provide? {questions.fifteen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.sixteen && <div className="answer"><div>There are two opportunities for presenting which are based on a company’s stage. Neither of the opportunities require an application fee and there is no fee taken upfront whatsoever.</div><br />

                  <div>Seed stage companies are not charged to present, besides the fee of purchasing a ticket to attend which is only $397 (a $400 discount off the “at the door” rate). For more info, check out: <a href="https://youngstartup.com/upcoming/top-innovators">https://www.account.youngstartup.com</a></div><br />

                  <div>Companies seeking series A-C which are selected to present are charged a total fee between $1,585-1785 (There is no fee to apply) and includes the following featured company benefits:</div><br />

                  <div>1-on-1 Networking Sessions</div><br />

                  <div>Connect and meet with active investors over the course of two and a half days leveraging our meeting app and conferencing solutions</div><br />

                  <div>Top Innovator Presentation Slot</div><br />

                  <div>Gain visibility for your venture by presenting live and being recognized as one of the Top Innovators</div><br />

                  <div>Featured Company Coaching Session</div><br />

                  <div>Opportunity to present to active investors and receive crucial feedback that will help you improve your game for the main presentation.</div><br />

                  <div>Featured Company Profile</div><br />

                  <div>Presenters have their 2-page company profile included in the event guide which is distributed to all attendees and investors</div><br />

                  <div>Access to Venture Investor Panels + Round Tables</div><br />

                  <div>Watch and hear interactive sessions, where leading investors and experts will discuss key issues facing startups and venture investors alike.</div><br />

                  <div>Alternatively, they can attend as general attendees where they can take advantage of networking with our many investors in attendance.</div><br />

                  <div>Companies who register to attend and are subsequently selected to present, will have their fee credited towards the presentation fee. </div><br />
               </div>}
               <br />
               <div className="divider"></div>
               <br />
               <div>
                  <div className="question" onClick={() => { questions.seventeen === false ? setQuestions({ ...questions, seventeen: true }) : setQuestions({ ...questions, seventeen: false }) }}>Have startups received funding via these summits? {questions.seventeen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
                  {questions.seventeen && <div className="answer">Thrive Bioscience, Peminic, Applause (UTest), Mimeo, Soft Tissue Regeneration, Powerhouse Dynamics are to name just a few. We have startups who have received funding and return to our summits to raise further rounds, as well as many VCs that come back for deal flow.

                     We also suggest taking a look at the many testimonials and video highlights we have both from the startups and investors that have joined us.</div>}
               </div>
               <br />
               <div className="divider"></div>
               <br />
               <div>
                  <div className="question" onClick={() => { questions.eighteen === false ? setQuestions({ ...questions, eighteen: true }) : setQuestions({ ...questions, eighteen: false }) }}>Can I know which VCs are going to be attending prior to the summit? {questions.eighteen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
                  {questions.eighteen && <div className="answer">Our website is updated on a frequent basis with the VCs that have confirmed their attendance as speakers/judges. Many others will be in attendance.</div>}
               </div>
               <br />
               <div className="divider"></div>
               <br />
               <div>
                  <div className="question" onClick={() => { questions.nineteen === false ? setQuestions({ ...questions, nineteen: true }) : setQuestions({ ...questions, nineteen: false }) }}>How many VCs will listen to my presentation? {questions.nineteen === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
                  {questions.nineteen && <div className="answer">Each track typically gathers 25-30 industry-specific investors.</div>}
               </div>
               <br />
               <div className="divider"></div>
               <br />
               <div>
                  <div className="question" onClick={() => { questions.twenty === false ? setQuestions({ ...questions, twenty: true }) : setQuestions({ ...questions, twenty: false }) }}>Are the summits suitable for international companies? {questions.twenty === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
                  {questions.twenty && <div className="answer">Yes. Furthermore, we’re seeing a steady trend that as the economy becomes more and more global, VCs are also becoming less and less insistent on location than they traditionally have been.

                     The Venture Summit actually attracts an extensive network of Investors from across the country. As such, the Investors in attendance are naturally taking a look at investments that are not necessarily local.

                     Additionally, the Venture Summit brings a very strong contingent of Corporate VCs to whom, of course, location is entirely not an issue.</div>}
               </div>
               <br />
               <div className="divider"></div>
               <br />
               <div>
                  <div className="question" onClick={() => { questions.twentyOne === false ? setQuestions({ ...questions, twentyOne: true }) : setQuestions({ ...questions, twentyOne: false }) }}>Do you provide coaching? {questions.twentyOne === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
                  {questions.twentyOne && <div className="answer">The first day of the summit includes a “Featured Company” Coaching Session where active VCs provide feedback to the startups selected as part of the Top Innovator program. Companies are randomly selected for coaching and we go through as many as we can.</div>}
               </div>
            </div>
            <br />
            <div className="divider"></div>
            <br />
            <h1 style={{ textAlign: "center", color: 'white', fontFamily: "Roboto Condensed, sans-serif", fontWeight: '900', marginTop: '2.5vh' }}>Registration and Tickets</h1>
            <div>
               <div className="question" onClick={() => { questions.twentyTwo === false ? setQuestions({ ...questions, twentyTwo: true }) : setQuestions({ ...questions, twentyTwo: false }) }}>What is the easiest way to register to attend? {questions.twentyTwo === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentyTwo && <div className="answer">One can register via <a href="https://youngstartup.com/events/summer22/registration">https://youngstartup.com/events/summer22/registration</a></div>}
            </div>  <br />  <div className="divider"></div>
            <br />  <div>
               <div className="question" onClick={() => { questions.twentyThree === false ? setQuestions({ ...questions, twentyThree: true }) : setQuestions({ ...questions, twentyThree: false }) }}>How much does it cost to attend? {questions.twentyThree === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentyThree && <div className="answer">Please see the above link. We have a number of packages to suit your budget.</div>}
            </div>   <br />   <div className="divider"></div>
            <br /> <div>
               <div className="question" onClick={() => { questions.twentyFour === false ? setQuestions({ ...questions, twentyFour: true }) : setQuestions({ ...questions, twentyFour: false }) }}>How many people from my company can I bring by registering {questions.twentyFour === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentyFour && <div className="answer">There is no limit to the number of people you can bring. On the registration page, there is an option to enter the relevant information for up to ten people. If you would like to register more than ten people, this can be done through an additional order. Please note each ticket purchased admits one person.</div>}
            </div>   <br />   <div className="divider"></div>
            <br /> <div>
               <div className="question" onClick={() => { questions.twentyFive === false ? setQuestions({ ...questions, twentyFive: true }) : setQuestions({ ...questions, twentyFive: false }) }}>Can I transfer my registration or change the name on my registration? {questions.twentyFive === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentyFive && <div className="answer">We will try to assist you, just email <a href="mailto:rivka@youngstartup.com">rivka@youngstartup.com</a> </div>}
            </div>  <br />   <div className="divider"></div>
            <br />  <div>
               <div className="question" onClick={() => { questions.twentySix === false ? setQuestions({ ...questions, twentySix: true }) : setQuestions({ ...questions, twentySix: false }) }}>Something major came up and I will be unable to attend. What is the cancellation policy? {questions.twentySix === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentySix && <div className="answer">We will refund 25% of the fee with written cancellations received no later than two weeks prior to the event date. After that date, no refunds will be issued.</div>}
            </div>   <br />   <div className="divider"></div>
            <br /> <div>
               <div className="question" onClick={() => { questions.twentySeven === false ? setQuestions({ ...questions, twentySeven: true }) : setQuestions({ ...questions, twentySeven: false }) }}>I am a journalist. How can I request a press pass? {questions.twentySeven === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentySeven && <div className="answer">We allocate a few passes for attendance by press. Click <a href="/events/summer22/press-passes">here</a> for more info.</div>}
            </div>   <br />   <div className="divider"></div>
            <br /> <div>
               <div className="question" onClick={() => { questions.twentyEight === false ? setQuestions({ ...questions, twentyEight: true }) : setQuestions({ ...questions, twentyEight: false }) }}>I’m broke! Anything you can do for me? {questions.twentyEight === false ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</div>
               {questions.twentyEight && <div className="answer">At every summit, we offer up to 25 FREE tickets for seed-stage founders that cannot afford the registration to attend. Click <a href="https://youngstartup.com/venture-nurturing/">here</a> for more information on our VentureNurturing™ program.</div>}
            </div>
            <br />

         </Box>
         <Footer />
      </div>
   );
};
